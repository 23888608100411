import React from 'react';
import AddressList from '../AddressList';
import UserInfo from '../UserInfo';
import request from 'arcdynamic-request';
import { setUser } from '../../actions';
import exc from '../../exception';
import history from '../../history';

export default React.createClass({
	_logout() {
		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'Authentication.logout',
			}).then(res => {
				if (res.success) setUser(false);
				return res;
			}),
		]).then(() => {
			history.push(arc.path.base);
		}).catch(exc);
	},
	render() {
		const userType = this.props.user._ext.type;

		return (
			<div className='Account'>
				<h1>Your Account</h1>
				<h3>Request History</h3>
				<ul>
					<li><a href={arc.path.base+'history'}>Your requests</a></li>
					{
						(userType === 'internal' || userType === 'broker' || userType === 'limited broker' || userType === 'only samples') ? <li><a href={arc.path.base+'customer-history'}>Customer requests</a></li> : null
					}
					{
						this.props.user && this.props.user.typeCode === 'super' ? <li><a href={arc.path.base+'report'}>Order Report</a></li> : null
					}
					</ul>
				<hr/>
				<h3>Address Book</h3>
				<AddressList/>
				<hr/>
				<h3>Account Settings</h3>
				<UserInfo user={this.props.user} />
				<hr/>
				<p><button className='submit-button' onClick={this._logout}>Sign Out</button></p>
			</div>
		);
	},
});
