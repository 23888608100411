import React from 'react';

export default ({ links })=> (
	<div className='BreadCrumbs'>
		{
			links.map((el,i) => 
				<div key={el.href+el.text} className='BreadCrumbs_item'>
					{
						(i > 0) ? <span className='BreadCrumbs_item_divider'>›</span> : null
					}
					{
						el.disabled ? <span>{el.text}</span> : <a href={el.href}>{el.text}</a>
					}
				</div>
			)
		}
	</div>
);