import React from 'react';
import ReactDOM from 'react-dom';
import Control from 'react-control';
import svgClose from '../../svgs/close.svg';
import Icon from '../Icon';

const Portal = React.createClass({
	componentDidMount() {
		this.node = document.createElement('div');
		document.body.appendChild(this.node);
		ReactDOM.render(this, this.props.children, this.node);
	},
	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this.node);
		document.body.removeChild(this.node);
	},
	componentWillReceiveProps: function(newProps) {
		ReactDOM.render(this, newProps.children, this.node);
	},
	render() {
		return null;
	},
});

const Modal = React.createClass({
	componentDidMount() {
		// Disable the document scrollbar & prevent layout shift
		const root = document.querySelector('html');
		root.style.paddingRight = (window.innerWidth - document.documentElement.clientWidth)+'px';
		root.style.overflow = 'hidden';

		// Remember the currently focused element
		this.activeElement = document.activeElement;

		// Close on Esc keypress
		document.addEventListener('keydown', this.handleEsc);
	},
	componentWillUnmount() {
		// Enable scrollbar
		const root = document.querySelector('html');
		root.style.overflow = '';
		root.style.paddingRight = '';

		// Restore previously focused element
		if (this.activeElement) {
			this.activeElement.focus();
		}

		// Unbind events
		document.removeEventListener('keydown', this.handleEsc);
	},
	handleEsc(e) {
		if (e.keyCode === 27) {
			e.preventDefault();
			this.props.onClose();
		}
	},
	render() {
		return (
			<div className='Modal' onClick={(e)=> {
					if (e.target === e.currentTarget) {
						this.props.onClose();
					}
				}}>
				<div className='Modal_body'>
					<div className='Modal_content'>
						{this.props.children}
					</div>
					<Control className='Modal_close' onClick={this.props.onClose}>
						<Icon svg={svgClose}/>
					</Control>
				</div>
			</div>
		);
	}
});

export default React.createClass({
	render() {
		return this.props.usePortal ? (<Portal><Modal {...this.props}/></Portal>) : <Modal {...this.props}/>;
	},
});