import React from 'react';
import FindProduct from '../FindProduct';

export default React.createClass({
	render() {
		return (
			<div className='SampleIndividual'>
				<FindProduct sampleType={this.props.sampleType} requestType={this.props.requestType} data={this.props.data} allowSpecialInstructions={false} allowOptionalAttributes={true} userType={this.props.userType}/>
			</div>
		);
	},
});
