import React from 'react';
import request from 'arcdynamic-request';
import Address from '../Address';
import AddressForm from '../AddressForm';
import Alert from '../Alert';
import FormContainer from '../FormContainer';
import exc from '../../exception';

export default React.createClass({
	propTypes: {
		address: React.PropTypes.object,
		onSuccess: React.PropTypes.func.isRequired,
		onCancel: React.PropTypes.func,
		attentionName: React.PropTypes.bool,
		phone: React.PropTypes.bool,
	},
	getInitialState() {
		return {
			errors: [],
			suggestedAddresses: [],
			suggestedCityStatePostalCodeCombinations: [],
			isBusy: null,
			hasUnsavedChanges: false,
			hasSubmitted: false,
		};
	},
	save(formValues, skipValidate){
		this.setState({
			errors: [],
			suggestedAddresses: [],
			suggestedCityStatePostalCodeCombinations: [],
			isBusy: true,
		});

		this.saveAddress(formValues, skipValidate).then(res=>{
			if(res.success && !res.suggestedAddresses.length && !res.suggestedCityStatePostalCodeCombinations.length){
				Promise.resolve(this.props.onSuccess(res.data)).then(()=>{
					if(this.isMounted()) {
						this.setState({
							isBusy:null,
							hasUnsavedChanges: false,
						});
					}
				});
			} else {
				if(!this.isMounted()) return;

				this.setState({
					isBusy: null,
					hasUnsavedChanges: false,
					errors: res.errors,
					suggestedAddresses: res.suggestedAddresses,
					suggestedCityStatePostalCodeCombinations: res.suggestedCityStatePostalCodeCombinations,
				});
			}
		}).catch(exc);
	},
	onSubmit(e) {
		e.preventDefault();

		// Triggers :invalid styles for Safari, since it lack HTML5 validation
		this.setState({hasSubmitted: true});

		this.save(this.refs.address.getAddress());
	},
	saveAddress(formValues, skipValidate){
		const data = {
			success: false,
			errors: [],
			suggestedAddresses: [],
			suggestedCityStatePostalCodeCombinations: [],
		};
		const reqs = [];
		const country = arc.countries.filter(x => formValues.country === x.code)[0];

		if (!skipValidate && country && country.validatePostalCode) {
			reqs.push(
				request(arc.path.store,{
					service: 'cart',
					action: 'store.Address.validatePostalCode',
					params: [formValues, {
						ignoreDisabledError: true,
					}],
					stopOnError: true,
					stopOnWarning: true,
				}).then(res=>{
					if(!res.success){
						data.errors = [res.message || 'Unspecified postal code validation error'];
					} else if(res.data.length || res.errors.length) {
						data.errors = res.errors.length ? res.errors : ['Unable to verify city, state, postal code combination']
						data.suggestedCityStatePostalCodeCombinations = res.data;
					}
				})
			);
		}

		if (!skipValidate && country && country.validate) {
			reqs.push(
				request(arc.path.store,{
					service: 'cart',
					action: 'store.Address.validate',
					params: [formValues, {
						ignoreDisabledError: true,
					}],
					stopOnError: true,
					stopOnWarning: true,
				}).then(res=>{
					if(!res.success){
						if(!data.errors.length){
							data.errors = [res.message || 'Unspecified validation error'];
						} else {
							//noop - previous validation failed
						}
					} else if(res.data.length || res.errors.length) {
						data.errors = res.errors.length ? res.errors : ['Unable to verify address']
						data.suggestedAddresses = res.data;
					}
				})
			);
		}

		reqs.push(
			request(arc.path.store, {
				service: 'cart',
				action: 'store.Address.update',
				params: [this.props.address ? this.props.address.id : null],
				options: {
					value: formValues
				}
			}).then((res)=>{
				if (res.success) {
					data.success = true;
					data.data = res.data;
				} else if(!data.errors.length){
					data.errors = [res.message || 'Unspecified error'];
				}
			})
		);

		return Promise.all(reqs).then(()=> data).catch(exc);
	},
	mergeSuggestedAddress(suggestion){
		const addr = this.refs.address.getAddress();

		return { 
			...addr,
			city: suggestion.city,
			state: suggestion.state,
			postalCode: suggestion.postalCode,
			country: suggestion.country,
			addressOne: suggestion.addressOne || addr.addressOne,
		};
	},
	render() {
		return (
			<form ref='form' onSubmit={this.onSubmit} className={'SaveAddress '+(this.state.hasSubmitted ? 'validate' : '')}>
				{
					this.props.apoAlert ? <Alert type='warn'>We cannot ship to PO/APO/FPO addresses</Alert> : null
				}
				<FormContainer>
					<AddressForm ref='address' address={this.props.address} onChange={()=> this.setState({hasUnsavedChanges: true})} attentionName={this.props.attentionName} phone={this.props.phone}/>
				</FormContainer>
				{
					this.state.errors.length ? this.state.errors.map((x,i)=> <Alert key={i} type='warn'>{x.message || x}</Alert>) : null
				}
				{this.state.suggestedCityStatePostalCodeCombinations.length ? (
					<div className='SaveAddress_error'>
						<Alert type='warn'>
							<div>Suggested locations:</div>
							<div className='SaveAddress_suggested'>
							{this.state.suggestedCityStatePostalCodeCombinations.map((x,i)=>{
								const address = {city:x.city, state: x.state, postalCode: x.postalCode};
								return (
									<button type='button' key={i} className='SaveAddress_suggested_item' onClick={()=>this.save(this.mergeSuggestedAddress(x))}>
										<Address address={address}/>
									</button>
								);
							})}
							</div>
						</Alert>
					</div>
				) : null }
				{this.state.suggestedAddresses.length ? (
					<div className='SaveAddress_error'>
						<Alert type='warn'>
							<p>Suggested addresses:</p>
							<div className='SaveAddress_suggested'>
							{this.state.suggestedAddresses.map((x,i)=>(
								<button type='button' key={i} className='SaveAddress_suggested_item' onClick={()=>this.save(this.mergeSuggestedAddress(x),true)}>
									<Address address={x}/>
								</button>
							))}
							</div>
						</Alert>
					</div>
				) : null }

				<div className='SaveAddress_edit'>
					{
						this.props.onCancel ? (
							<button type='button' onClick={this.props.onCancel}>Cancel</button>
						) : null
					}
					{
						(this.state.errors.length && !this.state.hasUnsavedChanges) ? (
							<button type='button' key='save-as-is' className='submit-button' onClick={()=>this.save(this.refs.address.getAddress(),true)}>The address is correct, save as is</button>
						) : (
							<button type='submit' key='save' className='submit-button' data-is-busy={this.state.isBusy}>Save</button>
						) 
					}
				</div>
			</form>
		);
	},
});
