import React from 'react';
import request from 'arcdynamic-request';
import Paginate from '../Paginate';
import Results from '../Results';
import exception from '../../exception';
import history from '../../history';
import querystring from 'querystring';
import Currency from '../Currency';
import Suggest from '../Suggest';
import Modal from '../Modal';
import DatePicker from '../DatePicker';
import svgClose from '../../svgs/close.svg';
import Icon from '../Icon';

const limitCount = 100;

function formatDate(unixTimestamp) {
	if (unixTimestamp) {
		const d = new Date(unixTimestamp*1000);
		if (d.toString() !== 'Invalid Date') {
			return `${d.getUTCMonth()+1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
		}
	}
	return null;
}

function ISOtoUSA(string) {
	const x = string.split('-');
	return x[1]+'/'+x[2]+'/'+x[0];
}

const Report = React.createClass({
	getInitialState() {
		return {
			items: [],
			totalCost: false,
			totalCount: false,
			totalQuantity: false,
			page: 1,
			usernames: false,
			editStartDate: false,
			editEndDate: false,
			products: [],
		};
	},
	_fetchUsernames() {
		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.dataquery.execute',
			params: ['PORTAL_CUSTOMER_STAGE_USERS'],
			options: {
				filter: {
					where: [],
				},
			},
		}, {
			expires: 1000*60*1,
		}).then(res => {
			let usernames = false;

			if (Array.isArray(res.data)) {
				usernames = res.data.sort((a,b)=> {
					const A = (a.FIRST_NAME ? a.FIRST_NAME.toUpperCase()+' ' : '')+(a.LAST_NAME ? a.LAST_NAME.toUpperCase()+' ' : '')+(a.USERNAME ? `(${a.USERNAME})` : '');
					const B = (b.FIRST_NAME ? b.FIRST_NAME.toUpperCase()+' ' : '')+(b.LAST_NAME ? b.LAST_NAME.toUpperCase()+' ' : '')+(b.USERNAME ? `(${b.USERNAME})` : '');

					if (A < B) {
						return -1;
					}
					if (A > B) {
						return 1;
					}

					return 0;
				})
			}
			this.setState({
				usernames,
			});
		}).catch(exception);
	},
	_fetch() {
		const { 
			status,
			search,
			username,
			start,
			end,
		} = this.props.location.query;
		
		const page = Number(this.props.location.query.page) || 1;

		if (!search) {
			this.setState({
				items: [],
				products: [],
				totalCost: false,
				totalCount: false,
				totalQuantity: false,
				page: 1,
			})
			return;
		}

		this.setState({
			isFetching: true
		});

		let items = [...new Set(search.split('~'))].map(str => str.split('*'));

		items = items.filter(([sku, attributeValueId]) => {
			if (attributeValueId) {
				const containsSameProductWithoutOptionalAttr = items.some(arr => {
					if (arr[0] === sku && !arr[1]) {
						return true;
					}
				});
				if (containsSameProductWithoutOptionalAttr) {
					return false;
				}
			}
			return true;
		});

		Promise.all(items.map(([sku, attributeValueId]) => {
			const where = ['CW1'];
			const param = {
				SKU: sku,
				limit: limitCount,
				offset: (limitCount * page) - limitCount,
			};

			if (username) {
				param.USERNAME = username;
				where.push('CW2');
			}
			if (status) {
				param.ORDER_STATUS_CODE = status;
				where.push('CW3');
			}
			if (start) {
				const date = new Date(start);
				if (date.toString() !== 'Invalid Date') {
					param.SHIP_DATE_MIN = Math.round(date.getTime()/1000).toString();
					where.push('CW4');
				}
			} 
			if (end) {
				const date = new Date(end);
				date.setUTCDate(date.getUTCDate()+1);
				if (date.toString() !== 'Invalid Date') {
					param.SHIP_DATE_MAX = Math.round(date.getTime()/1000).toString();
					where.push('CW5');
				}
			}
			if (attributeValueId) {
				param.ATTRIBUTE_VALUE_ID = attributeValueId;
				where.push('CW6');
			}

			return Promise.all([
				request(arc.path.api, {
					service: 'arcimedes',
					action: 'open.dataquery.execute',
					params: ['ORDER_REPORT', {
						param,
						filter: {
							where
						},
					}],
				}, {
					expires: 1000*60*60*2,//2 minutes
				}),
				request(arc.path.api, {
					service: 'arcimedes',
					action: 'open.dataquery.execute',
					params: ['ORDER_REPORT_SUMMARY', {
						param,
						filter: {
							where
						},
					}],
				}, {
					expires: 1000*60*60*2,//2 minutes
				}),
				request(arc.path.store, {
					service: 'cart',
					action: 'store.product.get',
					schema: '[name,sku,attributeValues,optionalAttributes]',
					options: {
						filter: {
							sku,
						},
						limit: {
							count: 1,
						},
						flag: {
							file: true,
							attributeValue: true,
							optionalAttribute: true,
						},
					},
				}, {
					expires: 1000*60*60*2,//2 minutes
				}),
			])
		})).then(values => {
			let items = [];
			let totalCount = 0;
			let totalQuantity = 0;
			let totalCost = 0;
			let products = [];

			values.forEach(val => {			
				if (val[0] && val[0].data && val[0].data.length) {
					items = items.concat(val[0].data);
				}

				const count = val[1] && val[1].data && val[1].data.length ? Number(val[1].data[0].RECORD_COUNT) : 0;
				const quantity = val[1] && val[1].data && val[1].data.length ? Number(val[1].data[0].SUM_QUANTITY) : 0;
				const cost = val[1] && val[1].data && val[1].data.length ? Number(val[1].data[0].SUM_TOTAL_COST) : 0;

				totalCount += count;
				totalQuantity += quantity;
				totalCost += cost;

				if (val[2] && val[2].data && val[2].data.length) {
					products.push({
						totalCount: count,
						totalQuantity: quantity,
						totalCost: cost,
						product: val[2].data[0],
					});
				}
			});

			this.setState({
				isFetching: false,
				items,
				totalCount,
				totalQuantity,
				totalCost,
				products,
				page,
			});
		}).catch(exception);
	},
	_search(sku) {
		const query = {...this.props.location.query};
		delete query.page;

		if (query.search) {
			query.search +='~'+sku;
		} else {
			query.search = sku;
		}

		history.push('?'+querystring.stringify(query), false);
	},
	_setQuery(key, value) {
		const query = {...this.props.location.query};
		delete query.page;

		if (value) {
			query[key] = value;
		} else {
			delete query[key];
		}

		history.push('?'+querystring.stringify(query), false);
	},
	_setStartDate(dateString) {
		this._setQuery('start', dateString);
		this.setState({editStartDate: false});
	},
	_setEndDate(dateString) {
		this._setQuery('end', dateString);
		this.setState({editEndDate: false});
	},
	componentDidMount() {
		this._fetch();
		this._fetchUsernames();
	},
	componentDidUpdate(prevProps) {
		if (this.props.location.href !== prevProps.location.href) {
			this._fetch();
		}
	},
	_clearSearch() {
		this._setQuery('search', false);
	},
	_removeProduct(sku) {
		const { query } = this.props.location;
		delete query.page;
		const search = query.search.split('~');
		search.splice(search.indexOf(sku),1);
		history.push('?'+querystring.stringify({...query, search: search.join('~')}), false);
	},
	render() {
		const { 
			items,
			page,
			products,
			totalCount,
			totalCost,
			totalQuantity,
			isFetching,
			usernames,
			editStartDate,
			editEndDate,
		} = this.state;

		const { location } = this.props;
		const { 
			end,
			username,
			status,
			start,
			search,
		} = location.query;

		if (items == null) return <div/>;

		const minDate = new Date('2017-03-01');
		const maxDate = new Date();
		const skus = search ? search.split('~') : [];

		return (
			<div className='Report'>
				<h1>Order Report</h1>
				<Suggest handleSku={this._search}/>
				<div className='Report_nav'>
					<div className='Report_nav_filter'>
						<div className='Report_date'>
							<button type='button' onClick={()=> this.setState({editStartDate: true})} className='Report_date_label'>{start ? ISOtoUSA(start) : 'Ship date min'}</button>
							{
								start ? <button type='button' onClick={()=> this._setQuery('start', null)} className='Report_date_clear'><Icon svg={svgClose}/></button> : null
							}
						</div>
						{
							editStartDate ? (
								<Modal onClose={()=> this.setState({editStartDate: false})}>
									<div style={{width: '100vw', maxWidth: '400px'}}>
										<DatePicker setDate={this._setStartDate} initialDate={start ? new Date(start) : maxDate} minDate={minDate} maxDate={maxDate} selectedDate={start}/>
									</div>
								</Modal>
							) : null
						}
					</div>
					<div className='Report_nav_filter'>
						<div className='Report_date'>
							<button type='button' onClick={()=> this.setState({editEndDate: true})} className='Report_date_label'>{end ? ISOtoUSA(end) : 'Ship date max'}</button>
							{
								end ? <button type='button' onClick={()=> this._setQuery('end', null)} className='Report_date_clear'><Icon svg={svgClose}/></button> : null
							}
						</div>
						{
							editEndDate ? (
								<Modal onClose={()=> this.setState({editEndDate: false})}>
									<div style={{width: '100vw', maxWidth: '400px'}}>
										<DatePicker setDate={this._setEndDate} initialDate={end ? new Date(end) : maxDate} minDate={minDate} maxDate={maxDate} selectedDate={end}/>
									</div>
								</Modal>
							) : null
						}
					</div>
					<div className='Report_nav_filter'>
						Status: <select value={status || ''} onChange={(e)=> this._setQuery('status', e.target.value)}>
							<option value=''>All</option>
							<option value='invalid'>Invalid</option>
							<option value='pending'>Pending</option>
							<option value='processing'>Processing</option>
							<option value='completed'>Completed</option>
							<option value='cancelled'>Cancelled</option>
							<option value='returned'>Returned</option>
							<option value='refunded'>Refunded</option>
						</select>
					</div>
					<div className='Report_nav_filter'>
						User: <select value={username || ''} onChange={(e)=> this._setQuery('username', e.target.value)}>
							<option value=''>All</option>
							{
								usernames ? usernames.map(el => (
									<option value={el.USERNAME} key={el.USERNAME}>
									{el.FIRST_NAME ? el.FIRST_NAME+' ' : null}
									{el.LAST_NAME ? el.LAST_NAME+' ' : null}
									{el.USERNAME ? `(${el.USERNAME})` : null}
									</option>
								)) : null
							}
						</select>
					</div>
				</div>
				<div className='Report_products'>
					{
						skus.map((str,i) => {
							const [sku, attributeValueId] = str.split('*');

							let item;

							products.some(el => {
								if (el.product.sku === sku) {
									item = el;
									return true;
								}
							});

							if (!item) return null;

							const { product, totalCost, totalQuantity } = item;

							return (
								<div key={product.sku+'-'+i} className='Report_products_item'>
									<div className='Report_products_item_label'>
										<div>
											<div>{product.name}</div>
											<ul>
												<li>SKU: {product.sku}</li>
												<li>Quantity: {totalQuantity}</li>
												<li>Total: <Currency>{totalCost}</Currency></li>
											</ul>
										</div>
										{
											product.optionalAttributes && product.optionalAttributes.length ? product.optionalAttributes.map(oAttr => {
												const change = e =>{
													const attributeValueId = e.target.value;
													const search = skus.slice(0);
													search[i] = product.sku + (attributeValueId ? '*'+attributeValueId : '');
													history.push('?'+querystring.stringify({...location.query, search: search.join('~')}), false);
												};
												return (
													<div key={oAttr.id} className='Report_products_item_label_select'>
														<div>{oAttr.name}</div>
														<select value={attributeValueId || ''} onChange={change}>
															<option/>
															{
																oAttr.values.map(val => <option key={val.id} value={val.id}>{val.name}</option>)
															}
														</select>
													</div>
												);
											}) : null
										}
									</div>
									<button type='button' onClick={()=> this._removeProduct(product.sku)} className='Report_products_item_clear'><Icon svg={svgClose}/></button>
								</div>

							);
						})
					}
				</div>
				<div className='Report_body' data-is-busy={isFetching || null}>
					{
						items && items.length ? (
							<div>
								<div className='Report_total'>
									<Results count={limitCount} page={page} itemCount={items.length} totalCount={totalCount}/>
									<span>Quantity: {totalQuantity.toLocaleString()} | Cost: <Currency>{totalCost}</Currency></span>
								</div>
								<table>
									<thead>
										<tr>
											<th>Name</th>
											<th>Purchase Order Code</th>
											<th>Ship Date</th>
											<th>Status</th>
											<th>QTY</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										{
											items.map(el => {
												return (
													<tr key={el.ARCIMEDES_ROW_ID}>
														<td>{el.USERNAME}</td>
														<td>{el.PURCHASE_ORDER_CODE}</td>
														<td>{formatDate(el.SHIP_DATE)}</td>
														<td>{el.ORDER_STATUS_CODE}</td>
														<td>{el.QUANTITY}</td>
														<td>{el.TOTAL_COST}</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						) : null
					}
					<div className='Report_paginate'>
						<Paginate query={location.query} pathname={location.pathname} count={limitCount} page={page} itemCount={items.length} totalCount={totalCount}/>
					</div>
				</div>
			</div>
		);
	},
});

export default ({ user, location })=> {
	return user && user.typeCode === 'super' ? <Report location={location}/> : <div>Access to this page restricted to admins.</div>;
};