import React from 'react';
import Header from '../Header';
import Main from '../Main';
import catchFocus from '../../catch-focus';

// const Banner = React.createClass({
// 	render() {
// 		return (
// 			<div className='Banner'>
// 				<div className='Banner_body'>
// 				</div>
// 			</div>
// 		);
// 	}
// })

export default React.createClass({
	componentDidMount() {
		catchFocus(this.refs.root);
	},
	render() {
		const { user, children, requestType } = this.props;

		return (
			<div className='Root' ref='root'>
				<div className='Root_body'>
					<Header user={user}/>
					<Main>{children}</Main>
				</div>
			</div>
		);
	},
});
