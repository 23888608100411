import React from 'react';
import Icon from '../Icon';
import svgArrow from '../../svgs/arrow-right.svg';

export default ({ disabled, onClick, isBusy, isMuted })=> (
	<button onClick={onClick} disabled={disabled} className='Continue'>
		<span className={'Continue_style'+(isMuted ? ' Continue_style--muted' : '')} data-is-busy={isBusy || null}>
			Continue <Icon className='Continue_style_icon' svg={svgArrow}/>
		</span>
	</button>
);