import React from 'react';
import SaveAddress from '../SaveAddress';
import request from 'arcdynamic-request';
import exc from '../../exception';

export default React.createClass({
	_fetch(id) {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.get',
			options: {
				filter: {
					id,
				},
				limit: {
					count: 1,
				},
			},
		}).then((res)=>{
			if (this.isMounted()) {
				this.setState({
					isFetching: false,
					address: res.success && res.data.length ? res.data[0] : false,
					error: false,
				})
			}
		}).catch(exc)
	},
	_handleSuccess() {
		if (this.isMounted()) {
			this.setState({
				success: true,
			});
			history.back();
		}
	},
	getInitialState() {
		return {
			isFetching: true,
			address: false,
			error: false,
			success: false,
		};
	},
	componentDidMount() {
		if (this.props.id) {
			this._fetch(this.props.id);
		} else {
			this.setState({
				isFetching: false,
				address: false,
			})
		}
	},
	render() {
		if (this.state.isFetching) return null;

		const id = this.props.id;

		return (
			<div className='EditAddress'>
				{
					this.state.success ? (
						<div>
							<h1>{ id ? 'Address Updated' : 'Address Created' }</h1>
							<p><button onClick={()=> history.back()}>Return to previous page</button></p>
						</div>
						
					) : (
						<div>
							<h1>{ id ? 'Edit Address' : 'New Address' }</h1>
							<SaveAddress address={this.state.address || undefined} onSuccess={this._handleSuccess}/>
						</div>
					)
				}
			</div>
		);
	},
});
