import React from 'react';
import Control from 'react-control';
import svgClose from '../../svgs/close.svg';
import Icon from '../Icon';

export default ({onClick, children})=>(
	<Control className='TagButton' onClick={onClick}>
		<span className='TagButton_term'>{children}</span>
		<span className='TagButton_icon'><Icon svg={svgClose}/></span>
	</Control>
);