import React from 'react';
import Alert from '../Alert';
import request from 'arcdynamic-request';
import exc from '../../exception';

export default React.createClass({
	_handleSubmit(e) {
		e.preventDefault();
		this.verify(this.refs.verificationKey.value);
	},
	getInitialState() {
		return {
			error: null,
		};
	},
	getDefaultProps(){
		return {
			verificationKey: null,
			isBusy: null,
		};
	},
	componentDidMount(){
		const verificationKey = this.props.verificationKey;

		if(verificationKey) {
			this.verify(verificationKey);
		}
	},
	activate(accountCode){
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Account.activate',
			params: [accountCode,null],
		}).then((res)=>{
			// todo: success state

			if (this.isMounted()) {
				this.setState({
					isBusy: null,
					error: res.success ? false : res.message,
				});
			}
		}).catch(exc);
	},
	verify(verificationKey){
		if(!verificationKey) {
			this.setState({
				error: 'Please enter a verification key'
			})
			return;
		}

		this.setState({
			isBusy: true,
			error: false,
		});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Account.verify',
			params: [verificationKey],
		}).then((res)=>{
			if (res && res.success) {
				this.activate(res.data);
			} else {
				if (this.isMounted()) {
					this.setState({
						isBusy: null,
						error: res.message,
					});
				}
			}
		}).catch(exc);
	},
	render() {
		return (
			<div className='Verify'>
				<h3>Verify Account</h3>
				<p>Thank you for registering. Please enter the verification code sent to your registered e-mail.</p>
				<form onSubmit={this._handleSubmit}>
					<div className='Verify_field'>
						<input type='text' ref='verificationKey' name='verificationKey' autoFocus placeholder='Verification key' defaultValue={this.props.verificationKey || null}/>
					</div>
					<Alert type='error'>{this.state.error}</Alert>
					<button data-is-busy={this.state.isBusy || null} className='Verify_btn Verify_btn--verify'>Submit</button>
				</form>
				<button onClick={()=> this.props.login()} className='Verify_btn Verify_btn--verified'>I already verified my account</button>
			</div>
		);
	},
});
