import React from 'react';
import Root from './components/Root';
import Error404 from './components/Error404';
import Request from './components/Request';
import Samples from './components/Samples';
import SampleProducts from './components/SampleProducts';
import Delivery from './components/Delivery';
import Customer from './components/Customer';
import GetInfo from './components/GetInfo';
import Complete from './components/Complete';
import Account from './components/Account';
import OrderHistory from './components/OrderHistory';
import CustomerHistoryContainer from './components/CustomerHistoryContainer';
import EditAddress from './components/EditAddress';
import Review from './components/Review';
import ResetPassword from './components/ResetPassword';
import Welcome from './components/Welcome';
import Verify from './components/Verify';
import LoginPage from './components/LoginPage';
import Auth from './components/Auth';
import ForgotPassword from './components/ForgotPassword';
import ProductListContainer from './components/ProductListContainer';
import SampleIndividual from './components/SampleIndividual';
import Thanks from './components/Thanks';
import Report from './components/Report';

const base = arc.path.base;

export default {
	[base]: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Request userType={props.userType}/>
			</Auth>
		</Root>
	),
	[base+':requestType(merchandise|point-of-sale|sample)']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Samples userType={props.userType} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<GetInfo userType={props.userType} requestType={params.requestType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+':requestType(sample)/products']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Samples userType={props.userType} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<SampleProducts requestType={params.requestType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+':requestType(sample)/products/:sampleType(regular|standard)']: (params, props)=> {
		let chooser;
		const userType = props.userType;

		switch (params.sampleType) {
			case 'regular':
				chooser = <SampleIndividual requestType={params.requestType} sampleType={params.sampleType} data={props[params.requestType]} userType={userType}/>;
				break;
			case 'standard':
				chooser = <ProductListContainer requestType={params.requestType} sampleType={params.sampleType} data={props[params.requestType]} userType={userType}/>;
				break;
		}

		return (
			<Root user={props.user} requestType={params.requestType}>
				<Auth user={props.user}>
					<Samples userType={userType} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
						<SampleProducts sampleType={params.sampleType} requestType={params.requestType} data={props[params.requestType]}>
							{ chooser }
						</SampleProducts>
					</Samples>
				</Auth>
			</Root>
		);
	},
	[base+':requestType(merchandise|point-of-sale)/products']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Samples userType={props.userType} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<ProductListContainer requestType={params.requestType} data={props[params.requestType]} userType={props.userType}/>
				</Samples>
			</Auth>
		</Root>
	),

	[base+':requestType(merchandise|point-of-sale|sample)/shipping']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Samples userType={props.userType} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<Delivery requestType={params.requestType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+':requestType(merchandise|point-of-sale|sample)/customer']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Samples userType={props.userType} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<Customer userType={props.userType} requestType={params.requestType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+':requestType(merchandise|point-of-sale|sample)/review']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Samples userType={props.userType} requestType={params.requestType} data={props[params.requestType]} location={props.location}>
					<Review requestType={params.requestType} user={props.user} userType={props.userType} data={props[params.requestType]}/>
				</Samples>
			</Auth>
		</Root>
	),
	[base+'complete']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Complete userType={props.userType} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'account']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Account user={props.user}/>
			</Auth>
		</Root>
	),
	[base+'account/address/new']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<EditAddress/>
			</Auth>
		</Root>
	),
	[base+'account/address/:id']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<EditAddress id={params.id}/>
			</Auth>
		</Root>
	),
	[base+'history']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<OrderHistory  user={props.user} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'customer-history']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<CustomerHistoryContainer user={props.user} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'report']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Report user={props.user} location={props.location}/>
			</Auth>
		</Root>
	),
	[base+'account/reset-password']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<LoginPage>
				<ResetPassword verificationKey={props.location.query.key}/>
			</LoginPage>
		</Root>
	),
	[base+'account/password']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<LoginPage>
				<ForgotPassword/>
			</LoginPage>
		</Root>
	),
	[base+'account/signup']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Welcome/>
		</Root>
	),
	[base+'account/verify']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<LoginPage>
				<Verify verificationKey={props.location.query.key}/>
			</LoginPage>
		</Root>
	),
	[base+'thanks']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Thanks/>
		</Root>
	),
	['*']: (params, props)=> (
		<Root user={props.user} requestType={params.requestType}>
			<Auth user={props.user}>
				<Error404/>
			</Auth>
		</Root>
	),
};