import React from 'react';
import SampleSelector from '../SampleSelector';
import request from 'arcdynamic-request';
import Alert from '../Alert';

function USAtoISO(string) {
	const x = string.split('/');
	return x[2]+'-'+x[0]+'-'+x[1];
}

const dateMemo = (function(){
	const cache = {};

	return str => {
		if (!cache[str]) {
			const d = new Date(str);

			if (d.toString() !== 'Invalid Date') {
				cache[str] = d;
			}
		}
		return cache[str];
	}
}());

export default React.createClass({
	getInitialState() {
		return {
			showInstructions: false,
			currentDate: null,
		};
	},
	componentDidMount() {
		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.utils.Date.get',
			}),
			request(arc.path.api, {
				service: 'arcimedes',
				action: 'open.datasource.table.Data.getData',
				params: ['code', 'DISABLED_SAMPLE_SHIPPING_DATES', {
					filter: [
						{
							code: 'FIRST_DATE',
							text: '',
							type: 'notNull',
						},
						{
							code: 'LAST_DATE',
							text: '',
							type: 'notNull',
						},
					],
					limit: {
						count: 999,
						offset: 0,
					},
				}],
			}, {
				expires: 1000*60*60,
			}),
		]).then(values => {
			this.setState({
				currentDate: values[0].data ? new Date(values[0].data) : false,
				disabledDates: values[1].data,
			});
		});
	},
	_renderMain() {
		const { currentDate, disabledDates } = this.state;
		const { data, requestType, sampleType, children } = this.props;

		if (currentDate === null) {
			return null; // loading
		}

		if (currentDate === false) {
			return children; // failed to fetch, probably best to let them take their chances
		}

		if (!data || !data.info || !data.info.deliveryDate) {
			return <Alert type='error'>Please select a <a href={'/'+requestType}>delivery date.</a></Alert>;
		}

		const d = new Date(USAtoISO(data.info.deliveryDate));

		const disabled = disabledDates.filter(x => {
			if (x.SAMPLE_TYPES && (x.SAMPLE_TYPES.split(',').indexOf(sampleType) !== -1) && d >= dateMemo(x.FIRST_DATE) && d <= dateMemo(x.LAST_DATE)) {
				return x;
			}
		})[0];

		if (disabled) {
			return <Alert type='error'>{disabled.MESSAGE || <span>This <a href={'/'+requestType}>delivery date.</a> not allowed for this sample type at this time</span>}</Alert>;
		}

		return children;
	},
	render() {
		return (
			<div className='SampleProducts'>
				<h2>Request Samples</h2>
				<div hidden={!this.state.showInstructions}>
					<p>There are three types of samples that can be ordered:</p>
					<ol className='SampleProducts_desc'>
						<li>
							<strong>Regular Sample:</strong> product in an existing format:
							<ul>
								<li><em>Case:</em> full case</li>
								<li><em>Unit:</em> any product individually boxed/wrapped such as one cake, one T&S IW cookie or brownie (GF, nibble)</li>
								<li><em>Tray/Sleeve:</em> brownies, crumb cake, Indiv saucy or cheesecakes; 4-pack Cinn Roll tray (retail)</li>
								<li><em>2-pack:</em> tart bases, truffle cakes</li>
								<li><em>6-pack:</em> Annie's Individuals</li>
								<li><em>Tub:</em> Muffin Batter</li>
							</ul>
						</li>
						<li><strong>Standard Sample Kit:</strong> premade kit - no changes; see list of kits generally available</li>
					</ol>
					<p>All samples requests must be approved prior to processing the request.</p>
				</div>
				<p><button className='SampleProducts_more' type='button' onClick={()=> this.setState({ showInstructions: !this.state.showInstructions })}>{this.state.showInstructions ? 'Hide instructions' : 'Show instructions for requesting samples'}</button></p>
				<SampleSelector requestType={this.props.requestType} sampleType={this.props.sampleType} data={this.props.data}/>
				{ this._renderMain() }
			</div>
		);
	},
});
