import { updateLocation } from './actions';

const state = {};

export default {
	push(url, scrollToTop = true) {
		window.history.pushState(state, '', url);
		if (scrollToTop) window.scrollTo(0, 0);
		updateLocation(url);
	},
	replace(url, scrollToTop = true) {
		window.history.replaceState(state, '', url);
		if (scrollToTop) window.scrollTo(0, 0);
		updateLocation(url);
	}
};