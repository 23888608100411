function sampleReqs({ requestReason, deliveryDate }) {
	return Boolean(requestReason && deliveryDate);
}

function merchReqs({ purpose, purposeValue }, userType) {
	return Boolean(
		((userType === 'internal' || userType === 'broker' || userType === 'limited broker') ? purpose : true) && 
		purposeValue
	);
}

export default (requestType, info = {}, userType)=> {
	let ret;

	switch (requestType) {
		case 'sample':
			ret = sampleReqs(info);
			break;
		case 'merchandise':
			ret = merchReqs(info, userType);
			break;
		case 'point-of-sale':
			ret = true;
			break;
	}

	return ret;
};