import React from 'react';
import MiniOrder from '../MiniOrder';
import request from 'arcdynamic-request';

export default React.createClass({
	_fetch() {
		this.setState({
			data: null,
			isBusy: true,
		});

		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.dataquery.execute',
			params: ['ORDER_DETAIL_SUPPLEMENT', 
				{
					param: {
						PURCHASE_ORDER_CODE: this.props.order.purchaseOrderCode,
					},
				}
			],
		}, {
			expires: 1000*60*1,
		}).then(res => {
			if (!this.isMounted()) return;

			if (res && res.data && res.data.length) {
				this.setState({
					data: res.data[0],
					isBusy: false,
				});
			} else {
				this.setState({
					data: false,
					isBusy: false,
				});
			}
		});
	},
	getInitialState() {
		return {
			data: null,
			isBusy: true,
		};
	},
	componentDidMount() {
		this._fetch();
	},
	componentDidUpdate(prevProps) {
		if (prevProps.order.id !== this.props.order.id) {
			this._fetch();
		}
	},
	render() {
		const { order } = this.props;
		const { data } = this.state;
		let label = '';
		let billingAddress = false;

		if (data) {
			switch (data.REQUEST_TYPE) {
				case 'Merchandise':
					label = 'Merchandise';
					break;
				case 'POS':
					label = 'Point of Sale';
					break;
				case 'Sample':
					label = 'Samples';
					break;
			}

			billingAddress = {
				attentionName: data.BUSINESS_ADDRESS_ATTENTION_NAME,
				addressee: data.BUSINESS_ADDRESS_ADDRESSEE,
				addressOne: data.BUSINESS_ADDRESS_ADDRESS_ONE,
				addressTwo: data.BUSINESS_ADDRESS_ADDRESS_TWO,
				addressFour: data.BUSINESS_ADDRESS_ADDRESS_FOUR,
				city: data.BUSINESS_ADDRESS_CITY,
				state: data.BUSINESS_ADDRESS_STATE,
				postalCode: data.BUSINESS_ADDRESS_POSTAL_CODE,
				phone: data.BUSINESS_ADDRESS_PHONE,
			};
		}

		return <MiniOrder productLabel={label} order={order} purpose={data && data.REQUEST_PURPOSE} billingAddress={billingAddress}/>
	},
});
