import React from 'react';
import Product from '../Product';
import Alert from '../Alert';
import Continue from '../Continue';
import history from '../../history';
import { setData } from '../../actions';
import Textarea from '../Textarea';

export default React.createClass({
	_addProduct(product, quantity, sibling) {
		const data = this.props.data;
		const products = Array.isArray(data.products) ? data.products.slice(0) : [];
		const exists = products.some(el => {
			if (el.product.id === product.id && el.sampleType === this.props.sampleType) {
				el.quantity = quantity;
				el.sibling = sibling;
				return true;
			}
		})

		if (!exists) {
			products.push({ product, quantity, sampleType: this.props.sampleType, sibling });
		}

		setData(this.props.requestType, {...data, products });
	},
	_setOptionalAttribute(el, key, value) {
		const optionalAttributes = { ...el.optionalAttributes };

		if (value) {
			optionalAttributes[key] = value;
		} else {
			delete optionalAttributes[key];
		}

		const data = this.props.data;
		const products = data.products.slice(0);

		products[products.indexOf(el)] = { ...el, optionalAttributes };
		setData(this.props.requestType, {...data, products });
	},
	_setInstructions(el, instructions) {
		const data = this.props.data;
		const products = data.products.slice(0);

		products[products.indexOf(el)] = { ...el, instructions };
		setData(this.props.requestType, {...data, products });
	},
	_setSpecialRequest(e) {
		const data = this.props.data;
		const specialRequest = e.target.value;
		setData(this.props.requestType, {...data, specialRequest});
	},
	_handleSubmit(e) {
		e.preventDefault();
		history.push(arc.path.base+this.props.requestType+'/shipping');
	},
	render() {
		const { isFetching, error, data, sampleType, requestType, allowSpecialInstructions } = this.props;
		const { products } = data;
		const isReady = products && products.filter(el => el.quantity).length;

		if (isFetching) return null;

		return (
			<form onSubmit={this._handleSubmit} className='ProductList'>
				{
					error ? <Alert type='error'>{error}</Alert> : null
				}
				<div className='ProductList_list'>
					{
						products ? products.filter(el => {
							if (sampleType) {
								return el.sampleType === sampleType;
							} else {
								return true;
							}
						}).map(el => (
							<Product 
								key={el.product.id}
								product={el.product}
								requestType={requestType}
								sibling={el.sibling}
								optionalAttributes={el.optionalAttributes}
								quantity={el.quantity}
								onOptionalAttribute={(key, value)=> this._setOptionalAttribute(el, key, value)}
								onChange={this._addProduct}
								allowSpecialInstructions={allowSpecialInstructions}
								allowOptionalAttributes={true}
								onInstructions={(value)=> this._setInstructions(el, value)}
								instructions={el.instructions}
							/>
						)) : null
					}
				</div>
				{
					requestType === 'merchandise' ? (
						<Textarea label='Special Instructions' value={data.specialRequest} onChange={this._setSpecialRequest}/>
					) : null
				}
				<div>
					<Continue disabled={!isReady} isMuted={!isReady}/>
				</div>
			</form>
		);
	},
});
