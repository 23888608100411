import React from 'react';
import SamplesInfo from '../SamplesInfo';
import MerchInfo from '../MerchInfo';
import POSInfo from '../POSInfo';

export default React.createClass({
	render() {
		let ret = null;

		switch (this.props.requestType) {
			case 'sample':
				ret = <SamplesInfo {...this.props}/>;
				break;
			case 'point-of-sale':
				ret = <POSInfo {...this.props}/>
				break;
			case 'merchandise':
				ret = <MerchInfo heading='Merchandise Request Information' {...this.props}/>;
				break;
		}

		return ret;
	},
});
