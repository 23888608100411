import React from 'react';
import Control from 'react-control';
import history from '../../history';
import request from 'arcdynamic-request';
import exc from '../../exception';
import { resetData } from '../../actions';
import ReviewSec from '../ReviewSec';
import Alert from '../Alert';
import Address from '../Address';
import SpecialInstructions from '../SpecialInstructions';
import meetsInfoRequirements from '../../meets-info-requirements';
import Customer from '../Customer';

function USAtoISO(string) {
	const x = string.split('/');
	return x[2]+'-'+x[0]+'-'+x[1];
}

function purgeCart() {
	return request(arc.path.store, {
		service: 'cart',
		action: 'store.Cart.purge',
	})
}

function formatInfo(info = {}) {
	const keys = Object.keys(info);
	return keys.filter(key => info[key]).map(key => key+':\r\n'+info[key]).join('\r\n\r\n');
}

function saveComment(orderId, details) {
	return Promise.resolve(details ? request(arc.path.store, {
		service: 'cart',
		action: 'store.order.comment.update',
		params: [orderId, null, {
			value: {
				typeCode: 'message',
				details,
			}
		}],
	}) : false);
}

function getCart() {
	return request(arc.path.store, {
		service: 'cart',
		action: 'store.Cart.get',
	});
}

function completePurchase(addressId) {
	return request(arc.path.store, {
		service: 'cart',
		action: 'store.Cart.completePurchase',
		params: [
			[
				{
					value: {
						addressId,
					},
				}
			]
		],
	})
}

function addOptionalAttributes(products) {
	const reqs = [];

	products.filter(el => el.optionalAttributes && el.quantity).forEach(el => {
		Object.keys(el.optionalAttributes).forEach(key => {
			reqs.push(
				request(arc.path.store, {
					service: 'cart',
					action: 'store.cart.Order.addOptionalAttribute',
					params: [null, el.sibling ? el.sibling.id : el.product.id, el.optionalAttributes[key]],
				})
			)
		});
	})

	return Promise.all(reqs);
}

function addProducts(products) {
	return Promise.all(products.filter(el => el.quantity).map(el => {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.addProduct',
			params: [null, el.sibling ? el.sibling.id : el.product.id, el.quantity],
		})
	}))
}

function meetsRequirements(requestType, data, userType) {
	if (!data) return false;

	if (!data.products || !data.products.length || !data.products.filter(el => el.quantity).length) return false;

	if (!data.shippingAddressId) return false;

	if (!meetsInfoRequirements(requestType, data.info, userType)) return false;

	if (Customer.isRequired(userType) && !Customer.status(requestType, data).isComplete) return false;

	return true;
}

export default React.createClass({
	_fetch() {
		const { data } = this.props;
		const reqs = [];

		this.setState({isFetching: true});

		if (data.shippingAddressId) {
			reqs.push(
				request(arc.path.store, {
					service: 'cart',
					action: 'store.Address.get',
					options: {
						filter: {
							id: data.shippingAddressId,
						},
						limit: {
							count: 1,
						},
					},
				}).then(res => {
					return { shippingAddress: res.success ? res.data[0] : false };
				})
			)
		}

		if (data.customerAddressId) {
			reqs.push(
				request(arc.path.store, {
					service: 'cart',
					action: 'store.Address.get',
					options: {
						filter: {
							id: data.customerAddressId,
						},
						limit: {
							count: 1,
						},
					},
				}).then(res => {
					return { customerAddress: res.success ? res.data[0] : false };
				})
			)
		}

		Promise.all(reqs).then(values => {
			if (this.isMounted()) {
				this.setState(Object.assign({ isFetching: false }, ...values));
			}
		}).catch(exc)
	},

	_handleSubmit() {
		const { data } = this.props;

		this.setState({
			isSubmitting: true,
			error: null,
		})

		Promise.all([
			purgeCart(),
			addProducts(data.products),
			addOptionalAttributes(data.products),
			getCart(),
		]).then(values => {
			if (values[3].success) {
				const cart = values[3].data;
				const orderIndex = Object.keys(cart.orders)[0];

				return Promise.all([
					orderIndex,
					request(arc.path.store, {
						service: 'cart',
						action: 'store.Cart.setOrderShippingAddress',
						params: [orderIndex, data.shippingAddressId],
					}),
					request(arc.path.store, {
						service: 'cart',
						action: 'store.cart.Order.getShippingMethodGroup',
						params: [orderIndex],
					})
				]);
			} else {
				// todo error
			}
		})
		.then(values => {
			const orderIndex = values[0];
			const methods = values[2]; 

			const messages = [];

			if (data.specialRequest) {
				messages.push(data.specialRequest);
			}

			data.products.filter(el => el.quantity && (el.instructions)).forEach(el => {
				messages.push(`${el.product.name} (SKU: ${el.product.sku}):\r\n${el.instructions}`);
			});

			return request(arc.path.store, {
				service: 'cart',
				action: 'store.cart.Order.update',
				params: [orderIndex],
				options: {
					value: {
						shippingMethodId: methods.data && methods.data.methods && methods.data.methods.length ? methods.data.methods[0].id : false,
						shippingInstructions: data.shippingInstructions || false,
						message: messages.join("\r\n----------------------------------------------------------\r\n"),
					},
				},
			});
		})
		.then(() => {
			// todo: check values
			return completePurchase(data.customerAddressId || data.shippingAddressId);
		})
		.then(res => {
			if (res.success) {
				if (data && data.info && data.info.deliveryDate) {
					const DATE = USAtoISO(data.info.deliveryDate);
					
					request(arc.path.api, {
						service: 'arcimedes',
						action: 'open.datasource.table.Data.updateData',
						params: ['code', 'DELIVERY_DATES'],
						options: {
							value: [{DATE}]
						},
					});
				}
				
				const purchase = res.data;
				const comment = formatInfo(data.info);
				const orderId = res.data.orders[0].id;

				Promise.all([
					saveComment(orderId, comment),
				]).then(() => {
					// todo: ignore failure on this call?
					resetData(this.props.requestType);
					history.push(`${arc.path.base}complete?id=${purchase.code}`);
				})
			} else {
				if (this.isMounted()) {
					this.setState({
						error: res.message,
						isSubmitting: false,
					})
				}
			}
		})
		.catch(exc);
	},
	_showLoanAgreement() {
		const { products } = this.props.data;

		const isLoaned = products && products.length && 
			products.filter(el => el.quantity)
				.filter(el => el.product.tags && el.product.tags.length)
				.filter(el => el.product.tags.filter(x => x.code === 'showLoanAgreement').length).length;

		if (!isLoaned) return null;

		return (
			<p className='LoanAgreement'>It is agreed that the equipment listed above has been loaned by David’s Cookies at no charge as long as it is utilized for the marketing of David’s Cookies products only and maintained in excellent condition subject to normal usage and wear. Any damage or loss during or upon termination of usage shall be the responsibility of the user.</p>
		);
	},
	_showCustomerAddress() {
		const { shippingAddress, customerAddress } = this.state;
		const { data, requestType, userType } = this.props;

		if (!Customer.isRequired(userType)) { // Customers don't need a business address
			return null;
		}

		const results = Customer.status(requestType, data);

		if (results.isComplete) {
			if (customerAddress) {
				return (
					<ReviewSec title='Business Address' editHref={`${arc.path.base}${requestType}/customer`}>
						<Address address={customerAddress}/>
					</ReviewSec>
				);
			}

			if (shippingAddress) {
				return (
					<ReviewSec title='Business Address' editHref={`${arc.path.base}${requestType}/customer`}>
						<Address address={shippingAddress}/>
					</ReviewSec>
				);
			}
		} else {
			return results.alert;
		}
	},
	getInitialState() {
		return {
			isFetching: false,
			isSubmitting: false,
			addresses: [],
			error: null,
			addressId: null,
			purgeId: null,
		};
	},
	componentDidMount() {
		if (Object.keys(this.props.data).length) {
			this._fetch();
		}
	},
	componentDidUpdate(prevProps) {
		if (!Object.keys(prevProps.data).length && Object.keys(this.props.data).length) {
			this._fetch();
		}
	},
	_renderList(products) {
		return (
			<ul>
			{
				products.map(el => {
					const keys = el.optionalAttributes && Object.keys(el.optionalAttributes);
					const attrs = [];

					if (keys && keys.length) {
						keys.forEach(key => {
							for (let i = 0; i < el.product.optionalAttributes.length; i++) {
								for (let x = 0; x < el.product.optionalAttributes[i].values.length; x++) {
									if (el.product.optionalAttributes[i].values[x].id === Number(el.optionalAttributes[key])) {
										attrs.push(el.product.optionalAttributes[i].name+': '+el.product.optionalAttributes[i].values[x].name);
									}
								}
							}
						})
					}

					if (el.sibling && el.sibling.attributeValues.length) {
						attrs.push(el.sibling.attributeValues[0].attributeName+': '+el.sibling.attributeValues[0].name);
					}

					return (
						<li className='Review_product' key={el.product.id}>
							<div>{el.quantity} — {el.product.name}</div>
							<div>SKU: {el.sibling ? el.sibling.sku : el.product.sku}</div>
							{
								el.instructions ? <SpecialInstructions>{el.instructions}</SpecialInstructions> : null
							}
							{
								attrs.map((el,i) => <div key={i} className='Review_product_attr'>{el}</div>)
							}
						</li>
					)
				})
			}
			</ul>
		);
	},
	_renderProducts() {
		const { data, requestType } = this.props;
		const products = Array.isArray(data.products) && data.products.filter(el => el.quantity);

		if (!products.length) {
			return <Alert type='error'><a href={`${arc.path.base}${requestType}/products`}>Cart</a> is empty.</Alert>;
		}

		let label;

		switch (requestType) {
			case 'merchandise':
				label = 'Merchandise';
				break;
			case 'point-of-sale':
				label = 'Point of Sale';
				break;
			case 'sample':
				label = 'Samples';
				break;
			default:
				label = 'Items Requested';
				break;
		}

		let content;

		if (requestType === 'sample') {
			const sortedProducts = {};

			products.forEach(el => {
				if (!sortedProducts[el.sampleType]) {
					sortedProducts[el.sampleType] = [];
				}
				sortedProducts[el.sampleType].push(el);
			});

			const name = {
				'regular': 'Regular Samples',
				'standard': 'Standard Kit',
			};

			content = Object.keys(sortedProducts).map(sampleType => {
				return (
					<div key={sampleType}>
						<h4>{name[sampleType]}</h4>
						{ this._renderList(sortedProducts[sampleType])}
					</div>
				);
			});

		} else {
			content = this._renderList(products);
		}

		return (
			<ReviewSec title={label} editHref={`${arc.path.base}${requestType}/products`}>
				{ content }
				{
					data.specialRequest ? (
						<div className='Review_special'>
							<div className='Review_special_title'>Special Instructions</div>
							<div className='Review_special_body'>{data.specialRequest}</div>
						</div>
					) : null
				}
			</ReviewSec>
		);
	},
	render() {
		const { isSubmitting, error, shippingAddress, isFetching } = this.state;
		const { data, requestType } = this.props;
		const isReady = !isSubmitting && meetsRequirements(requestType, data, this.props.userType);

		if (isFetching) return null;

		return (
			<div className='Review'>
				<h2>Review your request</h2>
				{
					meetsInfoRequirements(requestType, data.info, this.props.userType) ? null : (
						<Alert type='error'><a href={`${arc.path.base}${requestType}`}>More information</a> is required.</Alert>
					)
				}
				{
					this._renderProducts()
				}
				{
					shippingAddress ? (
						<ReviewSec title='Shipping Address' editHref={`${arc.path.base}${requestType}/shipping`}>
							<Address address={shippingAddress}/>
						</ReviewSec>
					) : <Alert type='error'><a href={`${arc.path.base}${requestType}/shipping`}>More shipping information</a> required</Alert>
				}
				{
					this._showCustomerAddress()
				}
				{
					this._showLoanAgreement()
				}
				<Alert type='error'>{error}</Alert>
				<Control className={'Review_btn'+(!isReady ? ' Review_btn--muted' : '')} onClick={this._handleSubmit} disabled={!isReady} data-is-busy={isSubmitting || null}>Submit</Control>
			</div>
		);
	},
});
