import React from 'react';
import DatePicker from '../DatePicker';

const dateMemo = (function(){
	const cache = {};

	return str => {
		if (!cache[str]) {
			const d = new Date(str);

			if (d.toString() !== 'Invalid Date') {
				cache[str] = d;
			}
		}
		return cache[str];
	}
}());

function isAvailable(date, disabledDates) {
	if ([0,1,6].indexOf(date.getUTCDay()) > -1) {
		return false;
	}

	const isDisabled = disabledDates.some(x => {
		return !x.SAMPLE_TYPES && dateMemo(x.FIRST_DATE) && dateMemo(x.LAST_DATE) && (date >= dateMemo(x.FIRST_DATE) && date <= dateMemo(x.LAST_DATE))
	});

	return !isDisabled;
}

function USAtoISO(string) {
	const x = string.split('/');
	return x[2]+'-'+x[0]+'-'+x[1];
}

function ISOtoUSA(string) {
	const x = string.split('-');
	return x[1]+'/'+x[2]+'/'+x[0];
}

export default React.createClass({
	getInitialState() {
		return {
			minDate: null,
			maxDate: null,
		};
	},
	_setMinMax(dateString) {
		const minDate = new Date(dateString.slice(0,10)); // NOTE: sliced offset from UTC to treat store time as UTC time, rather than doing conversions.
		const hours = (new Date(dateString.slice(0,19)+'Z')).getUTCHours();

		let days = minDate.getUTCDay();

		if (days >= 6) {
			days = 7;
		} else if (days <= 0) {
			days = 1;
		}

		days += (hours >= 11 ? 4 : 3); // adjust depending if before or after 11am.

		if (days >= 6) {
			days += 2;
		}

		minDate.setUTCDate(minDate.getUTCDate()-minDate.getUTCDay()+days);

		const maxDate = new Date(minDate);

		maxDate.setUTCMonth(maxDate.getUTCMonth()+3);

		this.setState({
			minDate,
			maxDate,
		});
	},
	componentDidMount() {
		this._setMinMax(this.props.currentDate);
	},
	componentWillReceiveProps(nextProps) {
		this._setMinMax(nextProps.currentDate);
	},
	render() {
		const {
			minDate,
			maxDate,
		} = this.state;

		const {
			setDate,
			deliveriesPerDate,
			maximumDeliveriesPerDate,
		} = this.props;

		const deliveryDate = this.props.deliveryDate ? USAtoISO(this.props.deliveryDate) : undefined

		if (minDate === null) return null;

		return (
			<div className='DeliveryCalendar'>
				<DatePicker
					setDate={(d)=> setDate(ISOtoUSA(d))} initialDate={deliveryDate ? new Date(deliveryDate) : minDate}
					minDate={minDate}
					maxDate={maxDate}
					selectedDate={deliveryDate}
					isAvailable={date => {
						const isMaxed = (deliveriesPerDate[date.toISOString().slice(0,10)] || 0) >= maximumDeliveriesPerDate;
						return !isMaxed && isAvailable(date, this.props.disabledDates);
					}}/>
			</div>
		);
	},
});