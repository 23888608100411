import React from 'react';
import Control from 'react-control';

var getCalendar = function(date) {
	const d = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()));
	const month = d.getUTCMonth();
	const days = [];

	// Pad calendar with days from the previous month, if the first of the month does not coincide with the first of the week.
	d.setUTCDate(d.getUTCDate() - d.getUTCDay());

	while (days.length < 42) {
		const date = new Date(d);
		const isActive = date.getUTCMonth() === month;
		days.push({ date, isActive });
		d.setUTCDate(d.getUTCDate()+1);
	}

	const weeks = [];

	while (days.length) {
		weeks.push(days.splice(0, 7));
	}

	return weeks;
}

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

function defaultFormat(date, isActive) {
	return (
		<div key={date.getUTCDate()} className={'Calendar_day Calendar_day--'+(isActive ? 'active' : 'inactive')}>{date.getUTCDate()}</div>
	);
}

const Calendar = ({format = defaultFormat, minDate, maxDate, date, setDate})=> {
	minDate = minDate && !isNaN(minDate) ? minDate : null;
	maxDate = maxDate && !isNaN(maxDate) ? maxDate : null;

	if (isNaN(date)) { // No date or invalid date
		date = new Date();
	}

	if (minDate && minDate > date) { // Floor
		date = minDate;
	}

	if (maxDate && maxDate < date) { // Ceil
		date = maxDate;
	}

	const firstDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()));
	const lastDay = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0));

	function prevMonth() {
		setDate(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()-1)));
	}

	function nextMonth() {
		setDate(new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()+1)));
	}

	return (
		<div className='Calendar'>
			<div className='Calendar_head'>
				<Control onClick={prevMonth} disabled={minDate && minDate >= firstDay} className='Calendar_head_btn'>
					◀
				</Control>
				<div className='Calendar_head_month'>
					{monthNames[date.getUTCMonth()]} {date.getUTCFullYear()}
				</div>
				<Control onClick={nextMonth} disabled={maxDate && maxDate <= lastDay} className='Calendar_head_btn'>
					▶
				</Control>
			</div>
			<div className='Calendar_week'>
				<abbr title='Sunday'>Sun</abbr>
				<abbr title='Monday'>Mon</abbr>
				<abbr title='Tuesday'>Tue</abbr>
				<abbr title='Wednesday'>Wed</abbr>
				<abbr title='Thursday'>Thu</abbr>
				<abbr title='Friday'>Fri</abbr>
				<abbr title='Saturday'>Sat</abbr>
			</div>
			<div className='Calendar_body'>
				{
					getCalendar(date).map((week,i) => (
						<div key={i} className='Calendar_row'>
							{
								week.map(x => format(x.date, x.isActive))
							}
						</div>
					))
				}
			</div>
		</div>
	);
}

export default React.createClass({
	getInitialState() {
		return {
			date: this.props.initialDate,
		};
	},
	setDate(date) {
		this.setState({date});
	},
	render() {
		const {minDate, maxDate, format} = this.props;
		return <Calendar date={this.state.date} minDate={minDate} maxDate={maxDate} format={format} setDate={this.setDate}/>
	}
})
