import React from 'react';
import Alert from '../Alert';
import request from 'arcdynamic-request';
import exc from '../../exception';

export default React.createClass({
	_handleSubmit(e) {
		e.preventDefault();

		if (this.state.isBusy) return;

		this.setState({
			isBusy: true,
			error: false,
		})

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Customer.requestResetPassword',
			params: [this.refs.username.value],
		}).then((res)=>{
			if (!this.isMounted()) return;

			this.setState({
				success: res.success,
				error: !res.success ? res.message : false,
				isBusy: false,
			})
		}).catch(exc);
	},
	getInitialState() {
		return {
			error: false,
			isBusy: false,
		};
	},
	render() {
		const { error, isBusy, success } = this.state;
		return (
			<div className='ForgotPassword'>
				<h3>Password Reset</h3>
				{
					success ? (
						<div>
							<p>An email will be sent to that account's address shortly.</p>
						</div>
					) : (
						<div>
							<form onSubmit={this._handleSubmit}>
								<input ref='username' type='text' autoFocus placeholder='Username' required/> <button data-is-busy={isBusy || null} disabled={isBusy}>Submit</button>
							</form>
							<Alert type='error'>{error}</Alert>
						</div>
					)
				}
			</div>
		);
	},
});
