import React from 'react';
import request from 'arcdynamic-request';
import exc from '../../exception';
import Alert from '../Alert';
import history from '../../history';
import Input from '../Input';
import Select from '../Select';

export default React.createClass({
	_handleSubmit(e) {
		e.preventDefault();

		if (this.state.isBusy) return;

		const {
			EMAIL,
			FIRST_NAME,
			LAST_NAME,
			BUSINESS_NAME,
			TITLE,
			ADDRESS,
			CITY,
			STATE,
			ZIP,
			PHONE,
			HOW_LONG_BUYING,
			PRODUCTS,
			DISTRIBUTOR,
			PASSWORD,
			PASSWORD_CONFIRM,
		} = this.state;

		const phoneDigits = PHONE.match(/\d/g).length;

		if(phoneDigits < 10 || phoneDigits > 15) {
			this.setState({error: 'Phone number expected to have between 10 and 15 digits'})
			return
		}
		if(!EMAIL) {
			this.setState({error: 'Please enter an e-mail'})
			return
		}
		if(!FIRST_NAME || !LAST_NAME) {
			this.setState({error: 'Please enter your first and last name'})
			return
		}
		if(!PASSWORD) {
			this.setState({error: 'Please enter a password'})
			return
		}
		if(!PASSWORD_CONFIRM) {
			this.setState({error: 'Please retype the password'})
			return
		}
		if(PASSWORD !== PASSWORD_CONFIRM) {
			this.setState({error: 'Passwords do not match'})
			return
		}
		
		this.setState({isBusy: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Account.register',
			params: [EMAIL],
			options: {
				value: {
					password: PASSWORD,
					firstName: FIRST_NAME,
					lastName: LAST_NAME,
				}
			}
		}).then((res)=>{
			if (res.success) {
				const brokers = this.state.brokers.filter(el => el.STATE === this.state.STATE);

				request(arc.path.api, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.updateData',
					params: ['code','SIGNUP'],
					options: {
						value: [
							{
								EMAIL,
								FIRST_NAME,
								LAST_NAME,
								BUSINESS_NAME,
								TITLE,
								ADDRESS,
								CITY,
								STATE,
								ZIP,
								PHONE,
								HOW_LONG_BUYING,
								PRODUCTS,
								DISTRIBUTOR,
								BROKER_NAME: brokers.map(el => el.BROKER_NAME || 'N/A').join(', '),
								CONTACT: brokers.map(el => el.CONTACT || 'N/A').join(', '),
								BROKER_EMAIL: brokers.map(el => el.BROKER_EMAIL || 'N/A').join(', '),
							}
						]
					},
				}).then(res =>{
					if (!this.isMounted()) return; // todo

					if (res && res.success) {
						history.push(arc.path.base+'thanks');
					} else {
						this.setState({
							isBusy: null,
							error: `Signup successful, but was not able to save all information at this time due to this error: ${res.message}. A David's Cookies representative may contact you for additional information before activating your account.  Thank You!`,
						});
					}
				});
			} else {
				if (this.isMounted()) {
					this.setState({
						isBusy: null,
						error: res && !res.success ? res.message : null,
					});
				}
			}
		}).catch(exc);
	},

	_handleChange(e) {
		this.setState({[e.target.name]: e.target.value});
	},

	_fetch() {
		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: ['code', 'DAVIDSBROKERS', {
				limit: {
					count: 1000,
				},
			}],
		}, {
			expires: 1000*60*60,
		}).then((res)=>{
			if (!this.isMounted()) return;

			this.setState({
				brokers: res && res.success ? res.data : []
			});
		});
	},

	getInitialState() {
		return {
			isBusy: false,
			error: false,
			brokers: [],
		};
	},
	componentDidMount() {
		this._fetch();
	},
	render() {
		const { 
			isBusy, 
			error,

			EMAIL,
			FIRST_NAME,
			LAST_NAME,
			BUSINESS_NAME,
			TITLE,
			ADDRESS,
			CITY,
			STATE,
			ZIP,
			PHONE,
			HOW_LONG_BUYING,
			PRODUCTS,
			DISTRIBUTOR,
			PASSWORD,
			PASSWORD_CONFIRM,
		} = this.state;

		return (
			<form className='SignUp' onSubmit={this._handleSubmit}>
				<Input disabled={isBusy} required onChange={this._handleChange} type='email' value={EMAIL} name='EMAIL' label='Email:' autoFocus/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={FIRST_NAME} name='FIRST_NAME' label='First Name:'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={LAST_NAME} name='LAST_NAME' label='Last Name:'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={BUSINESS_NAME} name='BUSINESS_NAME' label='Business Name:'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={TITLE} name='TITLE' label='Title / Position:'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={ADDRESS} name='ADDRESS' label='Business Address:'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={CITY} name='CITY' label='City:'/>
				<Select disabled={isBusy} required onChange={this._handleChange} value={STATE} name='STATE' label='State:'>
					<option/>
					{this.state.brokers.map(el => el.STATE).filter((el, i, arr)=> arr.indexOf(el) === i).sort().map((state,i)=> <option key={i} value={state}>{state}</option>)}
				</Select>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={ZIP} name='ZIP' label='Zip Code:' pattern='\d{5}([\-]\d{4})?'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={PHONE} name='PHONE' label='Phone:'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={HOW_LONG_BUYING} name='HOW_LONG_BUYING' label='How long have you purchased David’s Cookies products?' placeholder=''/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={PRODUCTS} name='PRODUCTS' label='What products are you currently buying?' placeholder=''/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='text' value={DISTRIBUTOR} name='DISTRIBUTOR' label='Distributor:'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='password' value={PASSWORD} name='PASSWORD' label='Password (8 characters minimum):' autoComplete='new-password' pattern='.{8,}' title='Must be at least 8 characters'/>
				<Input disabled={isBusy} required onChange={this._handleChange} type='password' value={PASSWORD_CONFIRM} name='PASSWORD_CONFIRM' label='Confirm Password:' autoComplete='new-password' pattern='.{8,}' title='Must be at least 8 characters'/>
				<Alert type='error'>{error}</Alert>
				<button disabled={isBusy} data-is-busy={isBusy || null}>Submit</button>
			</form>
		);
	},
});
