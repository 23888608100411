import React from 'react';
import AddressPicker from '../AddressPicker';
import Continue from '../Continue';
import history from '../../history';
import { setData } from '../../actions';
import Textarea from '../Textarea';
import Alert from '../Alert';

const POBoxRX = /(p[\s\.]?o[\s\.]?\s*box)|post office/i

export default React.createClass({
	getInitialState() {
		return {
			error: null,
		};
	},
	_setAddress(address) {
		if (POBoxRX.test(address.addressOne)) {
			this.setState({error: 'We do not ship to PO Boxes'})
		} else {
			this.setState({error: null})
		}
		setData(this.props.requestType, {...this.props.data, shippingAddressId: address.id });
	},
	_submit() {
		const { requestType } = this.props;

		if (!this.state.error) {
			history.push(`${arc.path.base}${requestType}/customer`);
		}
	},
	render() {
		const { data, requestType } = this.props;

		return (
			<div>
				<h2>Shipping Address</h2>
				<p>Where would you like this shipped?<br/><br/><a href={arc.path.base+'account/address/new'} className='Delivery_link'>+ Add New Address</a></p>
				<AddressPicker addressId={data.shippingAddressId} onChange={this._setAddress} autoFocus={true}/>
				<Textarea placeholder='example: Deliver after 5pm. Request signature for delivery.' label='Shipping Instructions' value={data.shippingInstructions} onChange={(e)=> setData(requestType, { ...data, shippingInstructions: e.target.value })}/>
				{
					this.state.error && <Alert type='error'>{this.state.error}</Alert>
				}
				<Continue isMuted={!data.shippingAddressId || this.state.error} disabled={!data.shippingAddressId || this.state.error} onClick={this._submit}/>
			</div>
		);
	},
});
