import React from 'react';
import AddressPicker from '../AddressPicker';
import Continue from '../Continue';
import history from '../../history';
import { setData } from '../../actions';
import Select from '../Select';
import Input from '../Input';
import Alert from '../Alert';

function status(requestType, data) {
	const { customerAddressId, customerAddressSameAsShipping } = data;
	const info = data && data.info || {};

	if (requestType === 'sample' && !(info.isCurrentCustomer && info.distributor1 && info.marketSegment)) {
		return {
			alert: <Alert type='error'><a href={`${arc.path.base}${requestType}/customer`}>Customer Information</a> required</Alert>,
			isComplete: false,
		};
	}

	if (!(customerAddressId || customerAddressSameAsShipping)) {
		return {
			alert: <Alert type='error'><a href={`${arc.path.base}${requestType}/customer`}>Business address</a> required</Alert>,
			isComplete: false,
		};
	}

	return {
		isComplete: true,
	};
}

function isRequired(userType) {
	return !(userType !== 'broker' && userType !== 'internal' && userType !== 'limited broker' && userType !== 'only samples'); // Customers don't need a business address
}

export default React.createClass({
	statics: {
		status,
		isRequired,
	},
	_setAddress(address) {
		setData(this.props.requestType, {...this.props.data, customerAddressId: address.id, customerAddressSameAsShipping: false });
	},
	_setSameAsShipping(e) {
		setData(this.props.requestType, {...this.props.data, customerAddressId: false, customerAddressSameAsShipping: e.target.checked });
	},
	componentDidMount() {
		if (!isRequired(this.props.userType)) {
			history.replace(`${arc.path.base}${this.props.requestType}/review`);
		}
	},
	_setInfo(prop, value) {
		const data = this.props.data ? {...this.props.data} : {};
		
		data.info = data.info ? {...data.info} : {};
		data.info[prop] = value;
		setData(this.props.requestType, data);
	},
	_handleChange(e) {
		this._setInfo(e.currentTarget.name, e.currentTarget.value);
	},
	_handleSubmit(e) {
		e.preventDefault();
	},

	render() {
		const { customerAddressId, customerAddressSameAsShipping } = this.props.data;
		const info = this.props.data && this.props.data.info || {};
		const disabled = !status(this.props.requestType, this.props.data).isComplete;

		return (
			<div className='Customer'>
				{
					this.props.requestType === 'sample' ? (
						<form onSubmit={this._handleSubmit}>
							<h2>End-User Customer Information</h2>
							<div className='Customer_instructions'>
								<h4>INSTRUCTIONS:</h4>
								<ul>
									<li>If you have asked for this sample(s) to be sent to your brokerage address (or broker rep’s home address), <strong>please fill in the information for the end-user customer in the following sections</strong>.</li>
									<li>If the samples you are requesting are intended for multiple end-user customers you must list the remaining customers that will be receiving these samples in the “Shipping Instructions” box on the previous shipping page. The account name, contact name, city & phone number or email contact info is fine.</li>
									<li><strong>“Same as shipping address” should only be selected IF the items are physically shipping to the customer’s location.</strong></li>
								</ul>
							</div>
							<Select required name='isCurrentCustomer' value={info.isCurrentCustomer} onChange={this._handleChange} label='Are you currently buying our product?'>
								<option/>
								<option value='yes'>Yes</option>
								<option value='no'>No</option>
							</Select>
							<Select required name='marketSegment' value={info.marketSegment} onChange={this._handleChange} label="Customer's Market Segment">
								<option/>
								<option>Chain Restaurant</option>
								<option>Restaurant - Fine Dining</option>
								<option>Restaurant - Casual Dining</option>
								<option>Restaurant - Fast Casual</option>
								<option>Restaurant - QSR</option>
								<option>College & University</option>
								<option>K-12</option>
								<option>Healthcare</option>
								<option>Business & Industry</option>
								<option>Leisure, Sports & Entertai</option>
								<option>Caterer</option>
								<option>Hotels & Resorts</option>
								<option>C-store</option>
								<option>Grocery - InStore Bakery</option>
							</Select>
							<Input required name='distributor1' value={info.distributor1} onChange={this._handleChange} type='text' label='Who is your primary distributor?'/>
							<Input name='distributor2' value={info.distributor2} onChange={this._handleChange} type='text' label='Who is your secondary distributor?'/>
							<br/>
						</form>
					) : null
				}
				<h2>End-User Customer Physical Business Address</h2>
				<p>If the samples are being shipped to the brokerage address or the broker rep’s home address for the end-user customer, please enter the complete End-User Customer Physical Business Address here:</p>
				<p><a href={arc.path.base+'account/address/new'} className='Customer_link'>+ Add New Address</a></p>

				<label className='Customer_check'><input onChange={this._setSameAsShipping} checked={customerAddressSameAsShipping || false} type='checkbox'/> Same as shipping address</label>
				{
					!customerAddressSameAsShipping ? (
						<div>
							<AddressPicker addressId={customerAddressId} onChange={this._setAddress}/>
						</div>
					) : null
				}
				<Continue isMuted={disabled} disabled={disabled} onClick={()=> history.push(`${arc.path.base}${this.props.requestType}/review`)}/>
			</div>
		);
	},
});
