import React from 'react';
import OrderDetail from '../OrderDetail';
import request from 'arcdynamic-request';
import exc from '../../exception';

export default React.createClass({
	_fetch(id) {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Purchase.get',
			params: [id],
		}).then((res)=>{
			if (this.isMounted()) {
				this.setState({
					isFetching: false,
					purchase: res && res.success && res.data ? res.data : false,
				})
			}
		}).catch(exc);
	},
	getInitialState() {
		return {
			purchase: false,
			isFetching: true,
		};
	},
	componentDidMount() {
		this._fetch(this.props.location.query.id);
	},
	render() {
		const { purchase, isFetching } = this.state;

		if (isFetching) {
			return null;
		}

		return (
			<div className='Complete'>
				{
					purchase ? (
						<div>
							<h1>Request Sent</h1>
							<br/>
							{
								purchase.orders.map(el => <OrderDetail key={el.id} order={el}/>)
							}
						</div>
					) : (
						<div>
							<h1>Request not found</h1>
							<h3>See the <a href={arc.path.base+'history'}>request history</a> page for past requests.</h3>
						</div>
					)
				}
				{
					this.props.userType === 'broker' || this.props.userType === 'internal' || this.props.userType === 'limited broker' || this.props.userType === 'only samples' ? (
						<a className='Complete_btn' href={arc.path.base}>New Request</a>
					) : (
						<a className='Complete_btn' href={arc.path.base+'history'}>View Request History</a>
					)
				}
			</div>
		);
	},
});
