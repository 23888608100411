import React from 'react';
import history from '../../history';
import Continue from '../Continue';

export default React.createClass({
	render() {
		return (
			<div className='Thanks'>
				<div className='Thanks_body'>
					<h1>Thank you for signing up!</h1>
					<p>Your account signup request will be reviewed and once approved you will get an email from your representative.</p>
					<Continue onClick={()=> history.replace(arc.path.base)}/>
				</div>
			</div>
		);
	},
});
