import React from 'react';

export default ({title, editHref, children})=> (
	<div className='ReviewSec'>
		<div className='ReviewSec_head'>
			<div className='ReviewSec_head_title'>
				{title}
			</div>
			{editHref ? <a href={editHref}>Edit</a> : null}
		</div>
		<div className='ReviewSec_body'>
			{children}
		</div>
	</div>
);