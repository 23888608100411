import React from 'react';
import svgAngleDoubleLeft from '../../svgs/angle-double-left.svg';
import svgAngleDoubleRight from '../../svgs/angle-double-right.svg';
import Icon from '../Icon';
import querystring from 'querystring';

export default React.createClass({
	propTypes: {
		pathname: React.PropTypes.string.isRequired,
		query: React.PropTypes.object.isRequired,
		count: React.PropTypes.number.isRequired,
		page: React.PropTypes.number.isRequired,
		totalCount: React.PropTypes.oneOfType([
			React.PropTypes.number,
			React.PropTypes.bool,
		]).isRequired,
		itemCount: React.PropTypes.number.isRequired,
	},
	getDefaultProps() {
		return {
			page: 1, 
		};
	},
	render() {
		let hasMore = null;

		// Display nothing if there is less than 1 page of items.
		if ((this.props.totalCount !== false && this.props.itemCount === this.props.totalCount) || (this.props.page === 1 && this.props.itemCount < this.props.count)) {
			return null;
		}

		if (this.props.totalCount !== false) {
			// If totalCount is false then it was probably too inefficient to get the count for this query.
			hasMore = this.props.count * this.props.page < this.props.totalCount;
		} else {
			// There are probably more results if the itemCount matches the request count.
			hasMore = this.props.itemCount > 0 && this.props.itemCount == this.props.count;
		}

		const prevQuery = { ...this.props.query, page: this.props.page - 1};
		const nextQuery = { ...this.props.query, page: this.props.page + 1};

		if (prevQuery.page <= 1) {
			delete prevQuery.page;
		}

		if (!hasMore) {
			delete nextQuery.page;
		}

		return (
			<div className='Paginate'>
				<a href={this.props.pathname+'?'+querystring.stringify(prevQuery)} rel="prev" className='Paginate_btn' disabled={this.props.page <= 1}>
					<Icon svg={svgAngleDoubleLeft}/> Prev
				</a>
				<a href={this.props.pathname+'?'+querystring.stringify(nextQuery)} rel="next" className='Paginate_btn' disabled={!hasMore}>
					Next <Icon svg={svgAngleDoubleRight}/>
				</a>
			</div>
		);
	}
});