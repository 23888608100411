import React from 'react';
import Address from '../Address';
import Currency from '../Currency';

function formatDate(unixTimestamp) {
	const d = new Date(unixTimestamp*1000);
	return `${d.getUTCMonth()+1}/${d.getUTCDate()}/${d.getUTCFullYear()}`;
}

export default React.createClass({
	getInitialState() {
		return {
			isOpen: false,
		};
	},

	_toggle() {
		this.setState({isOpen: !this.state.isOpen});
	},

	render() {
		const { order, isSuper } = this.props;
		
		let productLabel;
		switch (order.REQUEST_TYPE) {
			case 'Merchandise':
				productLabel = 'Merchandise';
				break;
			case 'POS':
				productLabel = 'Point of Sale';
				break;
			case 'Sample':
				productLabel = 'Samples';
				break;
			default:
				productLabel = 'Products';
				break;
		}

		let products = [];
		if (order.SHIPPED_PRODUCTS) {
			try {
				products = JSON.parse(order.SHIPPED_PRODUCTS);
			} catch (e) {
				// todo: handle error
			}
		}

		let shipments = [];
		if (order.SHIPMENTS) {
			try {
				shipments = JSON.parse(order.SHIPMENTS);
			} catch (e) {
				// todo: handle error
			}
		}

		const address = {
			attentionName: order.SHIPPING_ADDRESS_ATTENTION_NAME,
			addressee: order.SHIPPING_ADDRESS_ADDRESSEE,
			addressOne: order.SHIPPING_ADDRESS_ADDRESS_ONE,
			addressTwo: order.SHIPPING_ADDRESS_ADDRESS_TWO,
			addressFour: order.SHIPPING_ADDRESS_ADDRESS_FOUR,
			city: order.SHIPPING_ADDRESS_CITY,
			state: order.SHIPPING_ADDRESS_STATE,
			postalCode: order.SHIPPING_ADDRESS_POSTAL_CODE,
			phone: order.SHIPPING_ADDRESS_PHONE,
		};

		const billingAddress = {
			attentionName: order.BUSINESS_ADDRESS_ATTENTION_NAME,
			addressee: order.BUSINESS_ADDRESS_ADDRESSEE,
			addressOne: order.BUSINESS_ADDRESS_ADDRESS_ONE,
			addressTwo: order.BUSINESS_ADDRESS_ADDRESS_TWO,
			addressFour: order.BUSINESS_ADDRESS_ADDRESS_FOUR,
			city: order.BUSINESS_ADDRESS_CITY,
			state: order.BUSINESS_ADDRESS_STATE,
			postalCode: order.BUSINESS_ADDRESS_POSTAL_CODE,
			phone: order.BUSINESS_ADDRESS_PHONE,
		};

		const names = [];
		if (billingAddress && billingAddress.attentionName) {
			names.push(billingAddress.attentionName);
		}
		if (billingAddress && billingAddress.addressee) {
			names.push(billingAddress.addressee);
		}

		return (
			<div className='COD'>
				<div className='COD_head'>
					{
						order.ORDER_DATE ? (
							<div className='COD_head_sec'>
								<div className='COD_head_sec_label'>Request Placed</div>
								{formatDate(order.ORDER_DATE)}
							</div>
						) : null
					}
					{
						order.REQUESTED_DELIVERY_DATE ? (
							<div className='COD_head_sec'>
								<div className='COD_head_sec_label'>Requested Delivery Date</div>
								{formatDate(order.REQUESTED_DELIVERY_DATE)}
							</div>
						) : (
							null
						)
					}	
					<div className='COD_head_sec'>
						<div className='COD_head_sec_label'>Status</div>
						<strong className={`COD_head_sec_status COD_head_sec_status--${order.ORDER_STATUS_CODE}`}>
							{order.ORDER_STATUS_CODE[0].toUpperCase() + order.ORDER_STATUS_CODE.slice(1)}
						</strong>
					</div>
					<div className='COD_head_sec'>
						<div className='COD_head_sec_label'>Customer Name / Business Name</div>
						<strong>{names.join(' / ') || null}</strong>
					</div>
					<div className='COD_head_sec'>
						<div className='COD_head_sec_label'>Request Number</div>
						{order.PURCHASE_ORDER_CODE}
					</div>
				</div>
				<button className='COD_toggle' type='button' onClick={this._toggle}>
					{this.state.isOpen ? <span>- Minimize Request Details</span> : <span>+ Expand Request Details</span>}
				</button>
				<div className='COD_body' hidden={!this.state.isOpen}>
					<div className='COD_addrs'>
						<div className='COD_sec'>
							<h4>Ship to</h4>
							<Address address={address}/>
						</div>
						{
							billingAddress ? (
								<div className='COD_sec'>
									<h4>Customer Account / Business Name</h4>
									<Address address={billingAddress}/>
								</div>
							) : null
						}
					</div>
					<div className='COD_sec'>
						<h4>{productLabel}</h4>
						<table>
							<thead>
								<tr>
									<th>Name</th>
									<th>SKU</th>
									<th>Qty</th>
									{ isSuper ? <th>Price</th> : null }
									{ isSuper ? <th>Total</th> : null }
								</tr>
							</thead>
							<tbody>
								{
									products.map((el,i) => {
										return (
											<tr key={i}>
												<td>
													<span>{el.name}</span>
													{
														el.fixedAttributeValues ? (
															el.fixedAttributeValues.map(x=>(
																<span key={x.attributeValueId} className='COD_products_attribute'>– {x.attributeName}: {x.name}</span>
															))
														) : null
													}
													{
														el.optionalAttributeValues ? (
															el.optionalAttributeValues.map(x=><span key={x.attributeValueId} className='COD_products_attribute'>– {x.attributeName}: {x.name}</span>)
														) : null
													}
												</td>
												<td>{el.productSku}</td>
												<td>{el.quantity}</td>
												{ isSuper ? <td>{el.cost ? <Currency>{el.cost}</Currency> : ''}</td> : null }
												{ isSuper ? <td>{el.cost ? <Currency>{el.cost * el.quantity}</Currency> : ''}</td> : null }
											</tr>
										)
									})
								}
							</tbody>
							{
								isSuper ? (
									<tfoot>
										<tr>
											<td colSpan={4} style={{textAlign: 'right'}}>Subtotal:</td>
											<td>{order.TOTAL_SHIPPED_PRODUCTS_COST ? <Currency>{order.TOTAL_SHIPPED_PRODUCTS_COST - (order.SHIPPING_COST ? order.SHIPPING_COST : 0)}</Currency> : 'N/A'}</td>
										</tr>
										<tr>
											<td colSpan={4} style={{textAlign: 'right'}}>Shipping:</td>
											<td>{order.SHIPPING_COST ? <Currency>{order.SHIPPING_COST}</Currency> : 'N/A'}</td>
										</tr>
										<tr>
											<td colSpan={4} style={{textAlign: 'right'}}>Total:</td>
											<td>{order.TOTAL_SHIPPED_PRODUCTS_COST ? <Currency>{order.TOTAL_SHIPPED_PRODUCTS_COST}</Currency> : 'N/A'}</td>
										</tr>
									</tfoot>
								) : null
							}
						</table>
						<h4>Shipment Details</h4>
						{ 
							shipments.length ? (
								<table>
									<thead>
										<tr>
											<th>Tracking Number</th>
											<th>Status</th>
											<th>Order Date</th>
											<th>Delivery Date</th>
										</tr>
									</thead>
									<tbody>
										{
											shipments.map((el,i) => (
												<tr key={i}>
													<td>{el.trackingNumber ? <a href={`https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${el.trackingNumber}`} target='_blank'>{el.trackingNumber}</a> : null}</td>
													<td>{el.statusCode ? el.statusCode[0].toUpperCase() + el.statusCode.slice(1) : null}</td>
													<td>{order.ORDER_DATE ? formatDate(order.ORDER_DATE) : null}</td>
													<td>{el.deliveryDate ? formatDate(el.deliveryDate) : null}</td>
												</tr>
											))
										}
									</tbody>
								</table>
							) : <div className='COD_no-ship-sherlock'>No shipments</div>
						}
						{
							order.SHIPPING_INSTRUCTIONS ? (
								<div>
									<h4>Shipping Instructions</h4>
									<div className='COD_instructions'>
										{ order.SHIPPING_INSTRUCTIONS }
									</div>
								</div>
							) : null
						}
						{
							order.REPRESENTATIVE_NOTES && isSuper ? (
								<div>
									<h4>Representative Notes</h4>
									<div className='COD_instructions'>
										{ order.REPRESENTATIVE_NOTES }
									</div>
								</div>
							) : null
						}
						{
							order.GIFT_MSG ? (
								<div>
									<h4>Gift Message</h4>
									<div className='COD_instructions'>
										{ order.GIFT_MSG }
									</div>
								</div>
							) : null
						}
						{
							order.MARKET_SEGMENT ? (
								<div>
									<h4>Market Segment</h4>
									<div className='COD_instructions'>
										{ order.MARKET_SEGMENT }
									</div>
								</div>
							) : null
						}
						{
							order.REQUEST_PURPOSE ? (
								<div>
									<h4>Purpose of Request</h4>
									<div className='COD_instructions'>
										{ order.REQUEST_PURPOSE }
									</div>
								</div>
							) : null
						}
					</div>
				</div>
			</div>
		);
	},
});