import React from 'react';
import Alert from '../Alert';
import request from 'arcdynamic-request';
import exc from '../../exception';
import history from '../../history';

export default React.createClass({
	_handleSubmit(e) {
		e.preventDefault();

		if (this.state.isBusy) return;

		const password = this.refs.password.value;
		const confirmPassword = this.refs.confirmPassword.value;

		if (password !== confirmPassword) {
			return  this.setState({error: 'Passwords do not match'});
		}

		this.setState({
			error: false,
			isBusy: true,
		})

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Customer.resetPassword',
			params: [this.props.verificationKey, password],
		}).then((res)=>{
			if (res && res.success) {
				history.push(arc.path.base);
			} else {
				if (this.isMounted()) {
					this.setState({
						error: res.message,
						isBusy: false,
					});
				}
			}
		}).catch(exc);
	},
	getInitialState() {
		return {
			error: false,
			isBusy: false,
		};
	},
	render() {
		if (!this.props.verificationKey) {
			return (<Alert type='error'>Verification key missing</Alert>)
		}

		const { isBusy, error } = this.state;

		return (
			<div className='ResetPassword'>
				<h3>Reset Password</h3>
				<form onSubmit={this._handleSubmit}>
					<input type='password' ref='password' required placeholder='New password' autoComplete='new-password' pattern='.{8,}' title='Must be at least 8 characters'/>
					<input type='password' ref='confirmPassword' required placeholder='Re-enter password' autoComplete='new-password' pattern='.{8,}' title='Must be at least 8 characters'/>
					<Alert className='ResetPassword_alert' type='error'>{error}</Alert>
					<button disabled={isBusy} data-is-busy={isBusy || null}>Submit</button>
				</form>
			</div>
		);
	},
});
