import React from 'react';
import Product from '../Product';
import Continue from '../Continue';
import Search from '../Search';
import history from '../../history';
import { setData } from '../../actions';

export default React.createClass({
	_addProduct(product, quantity, sibling) {
		const data = this.props.data;
		const products = Array.isArray(data.products) ? data.products.slice(0) : [];
		const exists = products.some(el => {
			if (el.product.id === product.id && el.sampleType === this.props.sampleType) {
				el.quantity = quantity;
				el.sibling = sibling;
				return true;
			}
		})

		if (!exists) {
			products.push({ product, quantity, sampleType: this.props.sampleType, sibling });
		}

		setData(this.props.requestType, {...data, products });
	},
	_setOptionalAttribute(el, key, value) {
		const optionalAttributes = { ...el.optionalAttributes };

		if (value) {
			optionalAttributes[key] = value;
		} else {
			delete optionalAttributes[key];
		}

		const data = this.props.data;
		const products = data.products.slice(0);

		products[products.indexOf(el)] = { ...el, optionalAttributes };
		setData(this.props.requestType, {...data, products });
	},
	_setInstructions(el, instructions) {
		const data = this.props.data;
		const products = data.products.slice(0);

		products[products.indexOf(el)] = { ...el, instructions };
		setData(this.props.requestType, {...data, products });
	},
	_remove(el) {
		const data = this.props.data;
		const products = Array.isArray(data.products) ? data.products.slice(0) : [];

		for (let i = 0; i < products.length; i++) {
			if (products[i].product === el) {
				products.splice(i, 1);
				setData(this.props.requestType, {...data, products });
				break;
			}
		}
	},
	_handleSubmit(e) {
		e.preventDefault();
		history.push(arc.path.base+this.props.requestType+'/shipping');
	},
	render() {
		const { sampleType, data, requestType, allowSpecialInstructions, allowOptionalAttributes, userType } = this.props;
		const products = data.products ? data.products.filter(el => sampleType === el.sampleType).reverse() : [];
		const isReady = data.products && data.products.filter(x => x.quantity).length;

		return (
			<div className='FindProduct'>
				<Search onSuccess={(product)=> this._addProduct(product, 0)} requestType={requestType} sampleType={sampleType} userType={userType}/>
				<form onSubmit={this._handleSubmit} className='FindProduct_list'>
					<div>
						{
							products.map(el => (
								<Product 
									key={el.product.id}
									product={el.product}
									requestType={requestType}
									sibling={el.sibling}
									allowOptionalAttributes={allowOptionalAttributes}
									optionalAttributes={el.optionalAttributes}
									quantity={el.quantity}
									onOptionalAttribute={(key, value)=> this._setOptionalAttribute(el, key, value)}
									onChange={this._addProduct} 
									onRemove={this._remove}
									allowSpecialInstructions={allowSpecialInstructions}
									onInstructions={(value)=> this._setInstructions(el, value)}
									instructions={el.instructions}
								/>
							))
						}
					</div>
					<div>
						<Continue disabled={!isReady} isMuted={!isReady}/>
					</div>
				</form>
			</div>
		);
	},
});
