import React from 'react';
import PhoneNumber from '../PhoneNumber';

export default ({address})=> (
	<address>
		{address.attentionName ? <div>Attn: {address.attentionName}</div> : null}
		<div>{address.addressee}</div>
		{address.careOfName ? <div>c/o {address.careOfName}</div> : null}
		<div>{[address.addressOne, address.addressTwo, address.addressThree].map((el,i)=> (
			<div key={i}>{el}</div>
		))}</div>
		<div>{address.city}, {address.state} {address.postalCode}</div>
		{
			address.phone ? <div>Phone: <a href={'tel:'+address.phone}><PhoneNumber number={address.phone}/></a></div> : null
		}
		{
			address.addressFour ? <div>Email: <a href={'mailto:'+address.addressFour}>{address.addressFour}</a></div> : null
		}
	</address>
);
