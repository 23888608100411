import React from 'react';
import ProductList from '../ProductList';
import request from 'arcdynamic-request';
import exc from '../../exception';

import { setData } from '../../actions';

function getProducts(requestType, sampleType, userType) {
	const tagCode = [];
	const tagValueCode = [];

	if (sampleType) {
		tagCode.push('sampleType');
		tagValueCode.push('sampleType,'+sampleType);
	}

	let categoryCode;
	if (requestType === 'merchandise') {
		categoryCode = 'merchandising';
	} else if (requestType === 'sample') {
		categoryCode = sampleType;
	} else {
		categoryCode = 'point-of-sale';
	}

	return request(arc.path.store, {
		service: 'cart',
		action: 'store.product.get',
		schema: '[id,name,sku,desc,files,attributeValues,optionalAttributes,quantityStatusCode,tags,limitPerOrder,_ext{pdfLinkText}]',
		options: {
			filter: { 
				tagCode,
				tagValueCode,
				typeCode: 'root',
				excludeTagValueCode: userType !== 'internal' ? ['userType,internal'] : null,
				categoryCode,
			},
			limit: {
				count: 100,
			},
			order: 'category-asc',
			flag: {
				file: true,
				tag: true,
				attributeValue: true,
			},
		},
	}, {
		expires: 1000*60*60,
	})
}

export default React.createClass({
	componentDidMount() {
		getProducts(this.props.requestType, this.props.sampleType, this.props.userType).then(res => {
			if (res.success) {
				const products = Array.isArray(this.props.data.products) ? this.props.data.products.slice(0) : [];

				res.data.forEach(el => {
					const found = products.filter(x => x.product.id === el.id)[0];

					if (!found) {
						products.push({ product: el, sampleType: this.props.sampleType });
					}
				})

				setData(this.props.requestType, { ...this.props.data, products });
			}

			if (this.isMounted()) {
				this.setState({
					isFetching: false,
					error: res.success ? false : res.message,
				})
			}
		}).catch(exc);
	},
	getInitialState() {
		return {
			isFetching: true,
			error: false,
		};
	},
	render() {
		return <ProductList {...this.state} data={this.props.data} requestType={this.props.requestType} sampleType={this.props.sampleType}/>;
	},
});
