import exception from './exception';
import ReactDOM from 'react-dom';
import routes from './routes';
import history from './history';
import localLink from 'local-link';
import store from './store';
import Router from 'bare-router';
import { updateLocation, setUser } from './actions';
import './broadcast';
import request from 'arcdynamic-request';

const router = Router(routes);

// Render Cycle
{
	const container = document.createElement('div');

	document.body.appendChild(container);

	function render(state) {
		const { handler, params } = router(state.location.pathname);

		if (handler) {
			const component = handler(params, state);
			ReactDOM.render(component, container);
		} else {
			container.textContent = 'Route not found';
		}
	}

	store.subscribe((state, prevState)=> {
		if (state.location !== prevState.location) {
			request(arc.path.store, {
				service: 'cart',
				action: 'store.Customer.get',
				schema: '{typeCode,id,email,username,person{firstName,lastName},_ext}',
			}).then((res)=>{
				if (res.data && (res.data._ext.type === 'internal' ||
					res.data._ext.type === 'broker')) {
					setUser(res.data);
				} else {
					setUser(false)
				}
			}).catch(exception);
		}
		render(state);
	});
}

// SPA Navigation
{
	// Intercept anchor clicks
	window.addEventListener('click', (e) => {
		const anchor = localLink(e);

		if (!anchor) return;

		const { handler } = router(anchor.pathname);

		if (handler) {
			e.preventDefault();
			history.push(anchor.href);
		}
	});

	// Catch back/forward buttons
	window.addEventListener('popstate', ()=> {
		updateLocation(window.location);
	});
}

// Init
updateLocation(window.location);