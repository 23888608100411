import React from 'react';
import request from 'arcdynamic-request';
import CustomerOrderDetail from '../CustomerOrderDetail';
import Paginate from '../Paginate';
import Results from '../Results';
import exception from '../../exception';
import history from '../../history';
import querystring from 'querystring';
import Papa from 'papaparse';

const limitCount = 10;

function buildOptions({userType, user, limit, offset, isSuper}, q) {
	const options = {
		param: {
			limit,
			offset,
		},
		filter: {
			where: [],
		},
	};

	if (!isSuper) {
		if (userType === 'internal') {
			options.param.SALES_REPRESENTATIVE = `%"${user}"%`;
			options.filter.where.push('CW1');
		} else {
			options.param.BROKER = `%"${user}"%`;
			options.filter.where.push('CW2');
		}
	}

	if (q.salesRep) {
		options.param.SALES_REPRESENTATIVE = `%"${q.salesRep}"%`;
		options.filter.where.push('CW1');
	}

	if (q.type === 'Sample' || q.type === 'Merchandise' || q.type === 'POS') {
		options.param.REQUEST_TYPE = q.type;
		options.filter.where.push('CW3');
	}
	if (q.status) {
		options.param.ORDER_STATUS_CODE = q.status;
		options.filter.where.push('CW4');
	}
	if (q.username) {
		options.param.USERNAME = q.username;
		options.filter.where.push('CW5');
	}
	if (q.search) {
		options.param.search = '%'+q.search+'%';
		options.filter.where.push('CW6');
	}
	if (q.category) {
		options.param.SHIPPED_PRODUCT_CATEGORIES = `%"${q.category}"%`;
		options.filter.where.push('CW7');
	}
	if (q.type === 'custom' || q.type === 'regular' || q.type === 'standard') {
		options.param.SHIPPED_SAMPLE_TYPES = '%'+q.type+'%';
		options.filter.where.push('CW8');
	}
	if (q.sku) {
		options.param.SHIPPED_PRODUCT_SKUS = `%"${q.sku}"%`;
		options.filter.where.push('CW9');
	}
	if (q.instructions) {
		options.filter.where.push('CW10');
	}
	if (q.notes) {
		options.filter.where.push('CW11');
	}
	if (q.gift) {
		options.filter.where.push('CW12');
	}
	if (q.startDate) {
		const d = new Date(q.startDate+' GMT');
		if (d.toString() !== 'Invalid Date') {
			options.param.ORDER_DATE_MIN = Math.round(d.getTime()/1000);
			options.filter.where.push('CW13');
		}
	}
	if (q.endDate) {
		const d = new Date(q.endDate+' GMT');
		if (d.toString() !== 'Invalid Date') {
			d.setDate(d.getDate()+1);
			options.param.ORDER_DATE_MAX = Math.round(d.getTime()/1000);
			options.filter.where.push('CW14');
		}
	}
	if (q.shippingMin) {
		options.param.SHIPPING_COST_MIN = parseFloat(q.shippingMin.replace(/\$|,/g, '').trim())
		options.filter.where.push('CW15');
	}
	if (q.shippingMax) {
		options.param.SHIPPING_COST_MAX = parseFloat(q.shippingMax.replace(/\$|,/g, '').trim())
		options.filter.where.push('CW16');
	}
	if (q.submittedBy) {
		options.param.SUBMITTED_BY_REPRESENTATIVE = q.submittedBy;
		options.filter.where.push('CW17');
	}
	if (q.shipDateMin) {
		const d = new Date(q.shipDateMin+' GMT');
		if (d.toString() !== 'Invalid Date') {
			options.param.SHIP_DATE_MIN = Math.round(d.getTime()/1000);
			options.filter.where.push('CW18');
		}
	}
	if (q.shipDateMax) {
		const d = new Date(q.shipDateMax+' GMT');
		if (d.toString() !== 'Invalid Date') {
			d.setDate(d.getDate()+1);
			options.param.SHIP_DATE_MAX = Math.round(d.getTime()/1000);
			options.filter.where.push('CW19');
		}
	}

	return options;
}

export default React.createClass({
	getInitialState() {
		return {
			items: null,
			totalCount: false,
			page: 1,
			usernames: false,
			salesReps: false,
			categories: false,
			isSuper: false,
			submittedBy: false,
		};
	},
	_download() {
		let getData;

		this.setState({csvFetching: true});

		if (this.state.totalCount > limitCount) {
			const limit = 1000;
			const options = buildOptions({
				userType: this.props.userType, 
				user: this.props.username,
				limit: limit,
				offset: 0,
				isSuper: this.state.isSuper,
			}, this.props.location.query);

			const crawl = (offset, accumulator)=> {
				options.param.offset = offset;

				return request(arc.path.api, {
					service: 'arcimedes',
					action: 'open.dataquery.execute',
					params: ['PORTAL_CUSTOMER_STAGE'],
					options,
				}, {
					expires: 1000*60*5, // 5 minutes
				}).then(res => {
					const data = accumulator.concat(res.data || []);

					if (offset+limit < this.state.totalCount) {
						return crawl(offset+limit, data);
					} else {
						return data;
					}
				})
			};

			getData = crawl(0, []);
		} else {
			getData = Promise.resolve([...this.state.items]);
		}

		getData.then(data => {
			const items = [];

			data.forEach(el => {
				const item = {...el};

				if (item.ORDER_DATE) {
					const d = new Date(item.ORDER_DATE * 1000);
					item.ORDER_DATE = (d.toString() !== 'Invalid Date') ? d.toISOString().slice(0,10) : '';
				}

				if (item.REQUESTED_DELIVERY_DATE) {
					const d = new Date(item.REQUESTED_DELIVERY_DATE * 1000);
					item.REQUESTED_DELIVERY_DATE = (d.toString() !== 'Invalid Date') ? d.toISOString().slice(0,10) : '';
				}

				try {
					const trackingNumbers = JSON.parse(item.SHIPMENTS).map(x => x.trackingNumber).join('\r');
					item.SHIPMENTS = trackingNumbers;
				} catch (e) {
					// do nothing
				}

				try {
					const products = JSON.parse(item.SHIPPED_PRODUCTS).map(x => {
						const fixed = x.fixedAttributeValues ? x.fixedAttributeValues.map(z => `\r${z.attributeName}: ${z.name}`).join('') : '';
						const optional = x.optionalAttributeValues ? x.optionalAttributeValues.map(z => `\r${z.attributeName}: ${z.name}`).join('') : '';
						return `${x.name}${fixed}${optional}\rSKU: ${x.productSku} | QTY: ${x.quantity}`
					}).join('\r\r');
					item.SHIPPED_PRODUCTS = products;
				} catch (e) {
					// do nothing
				}

				items.push(item);
			});

			const text = Papa.unparse(items, {});
			const blob = new Blob([text], {type: 'text/csv'});
			const a = document.createElement('a');
			const url = URL.createObjectURL(blob);
			a.href = url;
			a.download = 'customer-history.csv';
			document.body.appendChild(a);
			a.setAttribute('hidden',true);
			a.click();
			document.body.removeChild(a);
			URL.revokeObjectURL(url);

			this.setState({
				csvFetching: null,
			});
		});
	},
	_fetchUsernames() {
		const { userType } = this.props;
		const options = {
			param: {},
			filter: {
				where: [],
			},
		};

		if (!this.state.isSuper) {
			if (userType === 'internal') {
				options.param.SALES_REPRESENTATIVE = `%"${this.props.username}"%`;
				options.filter.where.push('CW1');
			} else {
				options.param.BROKER = `%"${this.props.username}"%`;
				options.filter.where.push('CW2');
			}
		}

		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.dataquery.execute',
			params: ['PORTAL_CUSTOMER_STAGE_USERS'],
			options,
		}, {
			expires: 1000*60*1,
		}).then(res => {
			let usernames = false;
			let salesReps = false;

			if (Array.isArray(res.data)) {
				usernames = res.data.sort((a,b)=> {
					const A = (a.FIRST_NAME ? a.FIRST_NAME.toUpperCase()+' ' : '')+(a.LAST_NAME ? a.LAST_NAME.toUpperCase()+' ' : '')+(a.USERNAME ? `(${a.USERNAME})` : '');
					const B = (b.FIRST_NAME ? b.FIRST_NAME.toUpperCase()+' ' : '')+(b.LAST_NAME ? b.LAST_NAME.toUpperCase()+' ' : '')+(b.USERNAME ? `(${b.USERNAME})` : '');

					if (A < B) {
						return -1;
					}
					if (A > B) {
						return 1;
					}

					return 0;
				})

				res.data.forEach(user => {
					if (user.SALES_REPRESENTATIVE) {
						try {
							JSON.parse(user.SALES_REPRESENTATIVE).forEach(x => {
								if (!salesReps) {
									salesReps = {};
								}
								if (!salesReps[x]) {
									salesReps[x] = [];
								}
								salesReps[x].push(user.USERNAME);
							});
						} catch (e) {
							// ignore
						}
					}
				})
			}

			this.setState({
				usernames,
				salesReps,
			});
		}).catch(exception);
	},
	_fetchCategories() {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Category.getTree',
			schema: `[
				name,
				children,
			]`,
		}).then(res => {
			const hash = {};

			function dive(arr) {
				arr.forEach(x => {
					hash[x.name] = x.name;
					if (x.children && x.children.length) {
						dive(x.children);
					}
				});
			}

			if (res.data) {
				dive(res.data);
				this.setState({categories: Object.keys(hash).sort()});
			}
		});
	},
	_fetchSuper() {
		return request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.dataquery.execute',
			params: ['PERMISSIONS_TEST'],
		});
	},
	_fetchSubmittedBy() {
		request(arc.path.api, {
			service: 'arcimedes',
			action: 'open.dataquery.execute',
			params: ['PORTAL_CUSTOMER_BEHALF'],
		}, {
			expires: 1000*60*1,
		}).then(res => {
			if (res.data) {
				this.setState({
					submittedBy: res.data.sort(),
				});
			}
		}).catch(exception);
	},
	_fetch() {
		this.setState({isFetching: true});

		const page = Number(this.props.location.query.page) || 1;

		const options = buildOptions({
			userType: this.props.userType, 
			user: this.props.username,
			limit: limitCount,
			offset: page*limitCount - limitCount,
			isSuper: this.state.isSuper,
		}, this.props.location.query);

		Promise.all([
			request(arc.path.api, {
				service: 'arcimedes',
				action: 'open.dataquery.execute',
				params: ['PORTAL_CUSTOMER_STAGE'],
				options,
			}, {
				expires: 1000*60*5, // 5 minutes
			}),
			request(arc.path.api, {
				service: 'arcimedes',
				action: 'open.dataquery.execute',
				params: ['PORTAL_CUSTOMER_STAGE_COUNT'],
				options,
			}, {
				expires: 1000*60*5, // 5 minutes
			}),
		])
		.then(values => {
			this.setState({
				isFetching: false,
				page: page,
				items: values[0].data || [],
				totalCount: values[1].data && values[1].data[0].count ? Number(values[1].data[0].count) : false,
			})
		}).catch(exception);
	},
	_setQuery(key, value) {
		const query = {...this.props.location.query};
		delete query.page;

		if (value) {
			query[key] = value;
		} else {
			delete query[key];
		}

		history.push('?'+querystring.stringify(query), false);
	},
	_clearSearch() {
		this._setQuery('search', false);
	},
	componentDidMount() {
		this._fetchSuper().then(res => {
			this.setState({
				isSuper: res.success,
			}, ()=> {
				this._fetch();
				this._fetchUsernames();
				this._fetchCategories();
				this._fetchSubmittedBy();
			});
		});
	},
	componentDidUpdate(prevProps) {
		if (this.props.location.href !== prevProps.location.href) {
			this._fetch();
		}
	},
	render() {
		const { 
			items,
			page,
			totalCount,
			usernames,
			salesReps,
			isFetching,
			csvFetching,
			categories,
			isSuper,
			submittedBy,
		} = this.state;

		const { location } = this.props;

		if (items == null) return <div/>;

		return (
			<div className='CustomerHistory'>
				<h1>Customer Request History</h1>
				<form className='CustomerHistory_search' onSubmit={e => e.preventDefault()}>
					<input type='text' onChange={(e)=> this._setQuery('search', e.target.value)} value={location.query.search || ''} autoFocus placeholder='Search addressee, email, or phone'/>
				</form>
				<div className='CustomerHistory_nav'>
					<label className='CustomerHistory_nav_filter'>
						<span className='CustomerHistory_nav_filter_label'>User:</span>
						<select value={location.query.username || ''} onChange={(e)=> this._setQuery('username', e.target.value)}>
							<option value=''></option>
							{
								usernames ? usernames.map(el => (
									<option value={el.USERNAME} key={el.USERNAME}>
									{el.FIRST_NAME ? el.FIRST_NAME+' ' : null}
									{el.LAST_NAME ? el.LAST_NAME+' ' : null}
									{el.USERNAME ? `(${el.USERNAME})` : null}
									</option>
								)) : null
							}
						</select>
					</label>
					{
						isSuper && salesReps ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Broker:</span>
								<select value={location.query.username || ''} onChange={(e)=> this._setQuery('username', e.target.value)}>
									<option value=''></option>
									{
										Object.keys(salesReps).sort().map(rep => (
											<optgroup key={rep} label={rep}>
												{
													salesReps[rep].sort().map(u => <option key={u}>{u}</option>)
												}
											</optgroup>
										))
									}
								</select>
							</label>
						) : null
					}
					{
						isSuper && salesReps ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Sales Representative:</span>
								<select value={location.query.salesRep || ''} onChange={(e)=> this._setQuery('salesRep', e.target.value)}>
									<option value=''></option>
									{
										Object.keys(salesReps).sort().map(rep => <option key={rep}>{rep}</option>)
									}
								</select>
							</label>
						) : null
					}
					{
						isSuper && submittedBy ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Submitted By:</span>
								<select value={location.query.submittedBy || ''} onChange={(e)=> this._setQuery('submittedBy', e.target.value)}>
									<option value=''></option>
									{
										submittedBy.map(x => <option key={x.SUBMITTED_BY_REPRESENTATIVE}>{x.SUBMITTED_BY_REPRESENTATIVE}</option>)
									}
								</select>
							</label>
						) : null
					}
					<label className='CustomerHistory_nav_filter'>
						<span className='CustomerHistory_nav_filter_label'>Order Date Min:</span>
						<input type='text' onBlur={(e)=> this._setQuery('startDate', e.target.value)} defaultValue={location.query.startDate || ''}/>
					</label>
					<label className='CustomerHistory_nav_filter'>
						<span className='CustomerHistory_nav_filter_label'>Order Date Max:</span>
						<input type='text' onBlur={(e)=> this._setQuery('endDate', e.target.value)} defaultValue={location.query.endDate || ''}/>
					</label>
					{
						isSuper ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Ship Date Min:</span>
								<input type='text' onBlur={(e)=> this._setQuery('shipDateMin', e.target.value)} defaultValue={location.query.shipDateMin || ''}/>
							</label>
						) : null
					}
					{
						isSuper ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Ship Date Max:</span>
								<input type='text' onBlur={(e)=> this._setQuery('shipDateMax', e.target.value)} defaultValue={location.query.shipDateMax || ''}/>
							</label>
						) : null
					}
					{
						isSuper ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Shipping Cost Min:</span>
								<input type='text' onBlur={(e)=> this._setQuery('shippingMin', e.target.value)} defaultValue={location.query.shippingMin || ''}/>
							</label>
						) : null
					}
					{
						isSuper ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Shipping Cost Max:</span>
								<input type='text' onBlur={(e)=> this._setQuery('shippingMax', e.target.value)} defaultValue={location.query.shippingMax || ''}/>
							</label>
						) : null
					}
					{
						isSuper ? (
							<label className='CustomerHistory_nav_filter'>
								<span className='CustomerHistory_nav_filter_label'>Shipped SKU:</span>
								<input type='text' onBlur={(e)=> this._setQuery('sku', e.target.value)} defaultValue={location.query.sku || ''}/>
							</label>
						) : null
					}
					<label className='CustomerHistory_nav_filter'>
						<span className='CustomerHistory_nav_filter_label'>Status:</span>
						<select value={location.query.status || ''} onChange={(e)=> this._setQuery('status', e.target.value)}>
							<option value=''></option>
							<option value='invalid'>Invalid</option>
							<option value='pending'>Pending</option>
							<option value='processing'>Processing</option>
							<option value='completed'>Completed</option>
							<option value='cancelled'>Cancelled</option>
							<option value='returned'>Returned</option>
							<option value='refunded'>Refunded</option>
						</select>
					</label>
					<label className='CustomerHistory_nav_filter'>
						<span className='CustomerHistory_nav_filter_label'>Product Type:</span>
						<select value={location.query.type || ''} onChange={(e)=> this._setQuery('type', e.target.value)}>
							<option value=''></option>
							<option value='Merchandise'>Merchandise</option>
							<option value='POS'>Point of Sale</option>
							<optgroup label='Sample'>
								<option value='Sample'>Sample: All</option>
								<option value='custom'>Sample: Custom</option>
								<option value='regular'>Sample: Regular</option>
								<option value='standard'>Sample: Standard</option>
							</optgroup>
						</select>
					</label>
					<label className='CustomerHistory_nav_filter'>
						<span className='CustomerHistory_nav_filter_label'>Category:</span>
						<select value={location.query.category || ''} onChange={(e)=> this._setQuery('category', e.target.value)}>
							<option value=''></option>
							{
								categories ? categories.map(x => <option key={x}>{x}</option>) : null
							}
						</select>
					</label>
					<div className='CustomerHistory_nav_filter'>
						<label>
							<input type='checkbox' onChange={(e)=> this._setQuery('instructions', e.target.checked)} checked={location.query.instructions || false}/>
							Shipping Instructions
						</label>
						<label>
							<input type='checkbox' onChange={(e)=> this._setQuery('gift', e.target.checked)} checked={location.query.gift || false}/>
							Gift Message
						</label>
						{
							isSuper ? (
								<label>
									<input type='checkbox' onChange={(e)=> this._setQuery('notes', e.target.checked)} checked={location.query.notes || false}/>
									Representative Notes
								</label>
							) : null
						}
					</div>
				</div>
				<div className='CustomerHistory_results'>
					<Results count={limitCount} page={page} itemCount={items.length} totalCount={totalCount} clearSearch={this._clearSearch} query={location.query && location.query.search}/>
					<button disabled={isFetching || csvFetching || !items.length} onClick={this._download} data-is-busy={csvFetching || null}>Download CSV</button>
				</div>
				<div className='CustomerHistory_body' data-is-busy={isFetching || null}>
					{
						items ? items.map(el => {
							const heading = [];

							if (el.FIRST_NAME) {
								heading.push(el.FIRST_NAME);
							}
							if (el.LAST_NAME) {
								heading.push(el.LAST_NAME);
							}
							if (el.USERNAME) {
								heading.push('('+el.USERNAME+')');
							}

							return (
								<div className='CustomerHistory_order' key={el.PURCHASE_ORDER_CODE}>
									<div className='CustomerHistory_order_label'>
										{heading.join(' ')}
										{el.EMAIL ? <a href={'mailto:'+el.EMAIL} target='_blank' className='CustomerHistory_order_label_email'>{el.EMAIL}</a> : null}
									</div>
									<CustomerOrderDetail order={el} isSuper={isSuper}/>
								</div>
							)
						}) : null
					}
					<div className='CustomerHistory_paginate'>
						<Paginate query={location.query} pathname={location.pathname} count={limitCount} page={page} itemCount={items.length} totalCount={totalCount}/>
					</div>
				</div>
			</div>
		);
	},
});
