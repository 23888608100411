import React from 'react';
import history from '../../history';

const links = [
	{
		sampleType: 'regular',
		label: 'Regular Samples',
	},
	{
		sampleType: 'standard',
		label: 'Standard Kit',
	},
]

export default React.createClass({
	render() {
		const { requestType, sampleType, data } = this.props;

		return (
			<div className='SampleSelector'>
				{
					links.map(el => {
						const products = data && data.products ? data.products.filter(x => x.sampleType === el.sampleType && x.quantity) : [];

						return (
							<button type='button' key={el.sampleType} onClick={()=> history.push(`${arc.path.base}${requestType}/products/${el.sampleType}`, false)} className={'SampleSelector_btn'+(sampleType === el.sampleType ? ' SampleSelector_btn--active' : '')}>
								<div><span>{el.label}</span></div>
								{
									products.length ? <div>({products.length} selected)</div> : null
								}
							</button>
						)
					})
				}
			</div>
		);
	},
});
