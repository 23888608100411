// Globally broadcast state changes for use in outside scripts

import store from './store';

const CustomEvent = (function () {
  if ( typeof window.CustomEvent === "function" ) return window.CustomEvent;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  return CustomEvent;
}());

store.subscribe((state, prevState)=> {
	window.arc.state = state;
	// todo: prevent mutations
	document.body.dispatchEvent(
		new CustomEvent('onStateChange', {
			detail: {
				prevState,
				state
			}
		})
	);
})