import React from 'react';

let format = null;

// I would like to use Intl for formatting currency, 
// but the polyfill would add 200KBs to the minified and gzipped bundle.
if (typeof Intl === 'object') {
	format = Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
	}).format;
} else {
	// Fallback for IE <= 10 & Safari
	format = (value)=>{
		return '$' + parseFloat(value).toFixed(2).replace(/./g, function(c, i, a) {
			return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
		});
	}
}

export default React.createClass({
	statics: {
		format: format,
	},
	getDefaultProps() {
		return {
			children: 0,
		};
	},
	render() {
		return (<span {...this.props}>{format(this.props.children)}</span>);
	},
});
