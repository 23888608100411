import React from 'react';
import BreadCrumbs from '../BreadCrumbs';
import Alert from '../Alert';

export default ({ requestType, userType, children })=> {
	let hasPermission = false;

	if (requestType === 'merchandise' && (userType === 'broker' || userType === 'internal' || userType === 'limited broker' || userType === 'customer')) {
		hasPermission = true;
	} else if (requestType === 'point-of-sale' && (userType === 'broker' || userType === 'internal' || userType === 'limited broker')) {
		hasPermission = true;
	} else if (requestType === 'sample' && (userType === 'broker' || userType === 'internal' || userType === 'only samples')) {
		hasPermission = true;
	}

	const links = [
		{
			href: arc.path.base,
			text: 'Home',
			disabled: false,
		},
		{
			href: arc.path.base+requestType,
			text: 'Info',
			disabled: false,
		},
		{
			href: arc.path.base+requestType+'/products',
			text: 'Products',
			disabled: false,
		},
		{
			href: arc.path.base+requestType+'/shipping',
			text: 'Shipping',
			disabled: false,
		},
	];

	if (userType === 'broker' || userType === 'internal' || userType === 'limited broker' || userType === 'only samples') {
		links.push({
			href: arc.path.base+requestType+'/customer',
			text: 'Customer',
			disabled: false,
		});
	}

	links.push({
		href: arc.path.base+requestType+'/review',
		text: 'Review',
		disabled: false,
	});

	return (
		<div className='Samples'>
			<BreadCrumbs links={links}/>
			{ hasPermission ? children : <Alert type='warn'>Your account needs additional permissions to view this page.</Alert> }
		</div>
	);
};
