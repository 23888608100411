import React from 'react';
import Continue from '../Continue';
import Modal from '../Modal';
import history from '../../history';

export default React.createClass({
	getInitialState() {
		return {
			modal: false,
		};
	},
	render() {
		const { userType } = this.props;

		return (
			<div className='Request'>
				<h2>Select a request type</h2>
				<div className='Request_list'>
					{
						userType === 'broker' || userType === 'internal' || userType === 'limited broker' || userType === 'customer' ? (
							<a className='Request_item' href={arc.path.base+'merchandise'}>
								<div className='Request_item_icon'>
									<img alt='' src={arc.path.media+'v0/merch.jpg'}/>
								</div>
								<div>Merchandising</div>
							</a>
						) : null
					}
					{
						userType === 'broker' || userType === 'internal' || userType === 'limited broker' ? (
							<a className='Request_item' href={arc.path.base+'point-of-sale'}>
								<div className='Request_item_icon'>
									<img alt='' src={arc.path.media+'v0/pos.jpg'}/>
								</div>
								<div>POS</div>
							</a>
						) : null
					}
					{
						userType === 'broker' || userType === 'internal' || userType === 'only samples' ? (
							<a className='Request_item' href={arc.path.base+'sample'} onClick={e => {
								e.preventDefault();
								this.setState({modal: true});
							}}>
								<div className='Request_item_icon'>
									<img alt='' src={arc.path.media+'v0/sample.jpg'}/>
								</div>
								<div>Samples</div>
							</a>
						) : null
					}
				</div>
				{
					this.state.modal ? (
						<Modal onClose={()=> this.setState({modal: false})}>
							<div className='Request_modal'>
								<div>
									<p>Due to the current business environment, before entering any sample request into the portal we require a couple of simple steps:</p>
									<ul>
										<li>Sales Person is 100% sure the business is open and operating.</li>
										<li>Sales Person has conveyed to the operator that samples may arrive after 3 PM as UPS has been experiencing some delays.</li>
										<li>The operator understands that someone must be on premise to accept samples.</li>
										<li>If the sample(s) can't be delivered because no one is there to accept them, <b>we will not ship to this address again.</b></li>
										<li>Sales Person Understands he/she will be required to report to David’s RSM the result of the sample (sale/no sale).</li>
									</ul>
									<Continue onClick={()=> history.push(`${arc.path.base}sample`)}/>
								</div>
							</div>
						</Modal>
					) : null
				}
			</div>
		);
	}
})