import React from 'react';
import request from 'arcdynamic-request';
import Alert from '../Alert';
import Control from 'react-control';

function getProduct(filter, count) {
	return request(arc.path.store, {
		service: 'cart',
		action: 'store.product.get',
		schema: '[id,name,sku,desc,files,attributeValues,optionalAttributes,quantityStatusCode,tags,limitPerOrder,_ext{pdfLinkText}]',
		options: {
			filter,
			limit: {
				count: count,
			},
			order: 'category-asc',
			flag: {
				file: true,
				tag: true,
				attributeValue: true,
			},
		},
	}, {
		expires: 1000*60*60,
	})
}

export default React.createClass({
	_autoComplete(e) {
		const search = e.target.value.trim();
		const { sampleType, requestType, userType } = this.props;

		if (!search) {
			this.setState({
				products: false,
				error: false,
				isBusy: false,
				index: 0,
			})
			return;
		}

		this.setState({
			error: false,
			isBusy: true,
		});

		let categoryCode;
		if (requestType === 'merchandise') {
			categoryCode = 'merchandising';
		} else if (requestType === 'sample') {
			categoryCode = sampleType;
		} else {
			categoryCode = 'point-of-sale';
		}

		getProduct({ 
			search, 
			tagCode: ['sampleType'], 
			tagValueCode: ['sampleType,'+sampleType],
			typeCode: 'root',
			excludeTagValueCode: userType !== 'internal' ? ['userType,internal'] : null,
			categoryCode: requestType === 'merchandise' ? 'merchandising' : requestType,
		}, 10).then(res => {
			if (this.isMounted()) {
				this.setState({
					products: res.data,
					hasFocus: true,
					isBusy: false,
					error: res.success ? false : res.message,
					index: 0,
				})
			}
		});
	},
	_done(error) {
		if (this.isMounted()) {
			this.setState({
				isBusy: false,
				error,
			});
			this.refs.sku.value = '';
			this.refs.sku.focus();
		}
	},
	_setFocus(e) {
		if (!this.refs.auto || !this.refs.auto.contains(e.target)) {
			this.setState({
				hasFocus: false,
			})
		}
	},
	_keyControls(e) {
		switch (e.key) {
			case 'ArrowDown':
				this.setState({ index: Math.min(this.state.index+1, this.state.products && this.state.products.length ? this.state.products.length - 1 : 0) });
				e.preventDefault();
			break;
			case 'ArrowUp':
				this.setState({ index: Math.max(this.state.index-1,0) });
				e.preventDefault();
			break;
		}
	},
	_save(el) {
		this.setState({ products: false });
		this.refs.sku.value = '';
		this.refs.sku.focus();
		this.props.onSuccess(el);
	},
	_handleSubmit(e) {
		e.preventDefault();

		if (this.state.isBusy) return;

		if (this.state.products && this.state.products[this.state.index]) {
			this._save(this.state.products[this.state.index]);
		}
	},
	getInitialState() {
		return {
			isBusy: false,
			products: false,
			error: false,
			hasFocus: false,
		};
	},
	componentDidMount() {
		document.body.addEventListener('click', this._setFocus)
	},
	componentWillUnmount() {
		document.body.removeEventListener('click', this._setFocus)
	},
	render() {
		const { products, error, isBusy, hasFocus, index } = this.state;

		return (
			<div className='Search'>
				<div ref='auto' className='Search_auto'>
					<form onSubmit={this._handleSubmit}>
						<input onFocus={()=> this.setState({hasFocus: true})} onChange={this._autoComplete} onKeyDown={this._keyControls} autoFocus ref='sku' placeholder='Product SKU' type='text'/>
						<button disabled={isBusy} data-is-busy={isBusy || null} className='Search_btn'>Add</button>
					</form>
					{
						Array.isArray(products) && products.length && hasFocus ? (
							<div className='Search_auto_list'>
								{
									products.map((el,i) => (
										<Control className={'Search_auto_list_item'+(i === index ? ' Search_auto_list_item--selected' : '')} key={el.id} onClick={()=> this._save(el) }>
											{el.name} - SKU: {el.sku}
										</Control>
									))
								}
							</div>
						) : null
					}
				</div>
				<Alert type='error'>{error}</Alert>
			</div>
		);
	},
});
