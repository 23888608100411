import React from 'react';
import Control from 'react-control';
import Address from '../Address';
import request from 'arcdynamic-request';
import PaginateButtons from '../PaginateButtons';
import Results from '../Results';
import exc from '../../exception';

const limitCount = 8;

export default React.createClass({
	_handleSearch(e) {
		this.setState({search: e.target.value}, this._fetch);
	},
	_prev() {
		this._fetch(this.state.page-1);
	},
	_next() {
		this._fetch(this.state.page+1);
	},
	_fetch(page = 1) {
		this.setState({isFetching: true});

		request(arc.path.store, {
			service: 'cart',
			action: 'store.Address.get',
			options: {
				filter: {
					search: this.state.search || null,
				},
				limit: {
					count: limitCount,
					offset: (limitCount * page) - limitCount,
				},
				count: 2,
			},
		}).then(res => {
			if (!this.isMounted()) return;

			if (res.success) {
				this.setState({
					isFetching: false,
					addresses: res.data || [],
					error: !res.success ? res.message : null,
					page,
					totalCount: res.count,
				});
			}
		}).catch(exc)
	},
	getInitialState() {
		return {
			addresses: null,
			totalCount: null,
			search: false,
			page: 1,
		};
	},
	componentDidMount() {
		this._fetch();
	},
	purge(el) {
		Promise.all([
			request(arc.path.store, {
				service: 'cart',
				action: 'store.Address.purge',
				params: [el.id],
			}).then(() => {
				if (this.isMounted()) {
					// todo: error logging
					this._fetch(this.state.page);
				}
			}),
		]).catch(exc);
	},
	render() {
		const {
			isFetching,
			addresses,
			page,
			totalCount,
			search,
		} = this.state;

		if (!addresses) return <p>Loading…</p>;

		return (
			<div className='AddressList'>
				<div className='AddressList_new'>
					<a href={arc.path.base+'account/address/new'}>Add new address</a>
				</div>
				<form className='AddressList_search' onSubmit={e => e.preventDefault()}>
					<input type='text' onChange={this._handleSearch} value={search || ''} autoFocus placeholder='Search addressee, phone, or street address'/>
				</form>

				<div className='AddressList_list' data-is-busy={isFetching || null}>
				{
					addresses.map(el => (
						<div key={el.id} className='AddressList_list_item'>
							<Address address={el}/>
							<div className='AddressList_control'>
								<a href={arc.path.base+'account/address/'+el.id} className='AddressList_control_btn'>Edit</a> | <Control className='AddressList_control_btn' onClick={()=> this.purge(el)}>Delete</Control>
							</div>
						</div>
					))
				}
				</div>
				<div className='AddressList_results'>
					<PaginateButtons onNext={this._next} onPrev={this._prev} count={limitCount} page={page} totalCount={totalCount} itemCount={addresses.length}/>
					<Results count={limitCount} page={page} totalCount={totalCount} itemCount={addresses.length}/>
				</div>
			</div>
		);
	},
});
