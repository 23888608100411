import React from 'react';
import Calendar from '../Calendar';
import Control from 'react-control';

export default React.createClass({
	format(date, isActive) {
		const {
			minDate,
			maxDate,
			selectedDate,
			isAvailable,
			setDate,
		} = this.props;

		const dateString = date.toISOString().slice(0,10);

		if (!isActive) {
			return (
				<div key={date.getTime()} className={'DatePicker_day DatePicker_day--unavailable'}>{'\u00a0'}</div>
			);
		}

		if (date >= minDate && date <= maxDate && (isAvailable ? isAvailable(date) : true)) {
			return (
				<Control key={date.getTime()} onClick={()=>setDate(dateString)} className={'DatePicker_day DatePicker_day--available'+(dateString === selectedDate ? ' DatePicker_day--active' : '')}>
					<div className='DatePicker_day_date'>{date.getUTCDate()}</div>
				</Control>
			);
		}

		return (
			<div key={date.getTime()} className={'DatePicker_day DatePicker_day--unavailable'}>
				<div className='DatePicker_day_date'>{date.getUTCDate()}</div>
			</div>
		);
	},
	render() {
		const {
			minDate,
			maxDate,
			initialDate,
		} = this.props;

		return (
			<div className='DatePicker'>
				<Calendar format={this.format} initialDate={initialDate} minDate={minDate} maxDate={maxDate}/>
			</div>
		);
	},
});
